import React, { PureComponent } from 'react';
import { Upload, Icon, Button } from 'antd';
import PropTypes from 'prop-types';
import {sxAjax} from '@/commons/ajax';

export default class Uploader extends PureComponent {
    static propTypes = {
        type: PropTypes.any,          // 按钮的类型
        title: PropTypes.any,          // 按钮的title
        icon: PropTypes.any,          //按钮
        handleRes: PropTypes.func,  // 点击事件
    };
    state = {
        key: '',
        url: '/upload',
        imageUrl: '',
    }

  // 这里可以做上传之前的操作，比如文件大小的校验等
  beforeUpload = async (file) => {
    // const res = await this.fetchUploadToken();
    return true;
  }

  // 获取上传凭证
  fetchUploadToken = async () => {
    const params = {
      quantity: 1,
      module: 3,
      fileType: 1,
    };
    const res = await sxAjax.post('url',params);
    const { d, m } = res;
    if (m === 'success') {
      const { key, url } = d.l[0];
      this.setState({ key, url });
      return true;
    } else {
      return false;
    }
  }
  renderButton=(imageUrl)=>{
    const {type,crtImg,icon} = this.props
    if(type==="span"){
      return(
        <span style={{
          color:"rgba(36,133,190,1)",
          fontSize:14,
          fontWeight:400
        }}>上传头像</span>
      )
    }else if(type==="excel"){
      return  <Button icon="upload">导入</Button>
    }else{
      return (
        <div>
          { imageUrl || crtImg ? <img src={imageUrl || crtImg} alt='' style={{width:"100%",height:"100%"}}/> :( icon?icon:<Icon type='plus' />) }
        </div>
      )

    }
  }
  render() {
    const { imageUrl, url, key } = this.state;
    const {uploadParam,type} = this.props
    const that = this;
    const renderButton=that.renderButton(imageUrl);
    var upurl=url;
    if(uploadParam['upUrl']!=null){
      upurl=uploadParam['upUrl'];
    }
    const uploadProps = {
      name: 'file',
      showUploadList: false,
      multiple: false,
      accept: '.png, .jpg, .jpeg, .gif,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      action: upurl,
      beforeUpload: that.beforeUpload,
      // 这里需要指定文件上传的content-type
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      // 自定文件上传的方法，覆盖组件的 onChange 方法，可以定义上传不同阶段的行为（由 axios 默认提供）
      onStart(file) {
        console.log('onStart', file, file.name);
      },
      onSuccess(ret, file) {
        if(type!=="excel"){
          if(typeof(ret)==="string"){
            that.setState({
              imageUrl:ret
            })
          }else{
            that.setState({
              imageUrl:ret.data
            })
          }
        }
        

        that.props.handleRes(ret)
        // that.props.getData(key);
      },
      onProgress({ percent }, file) {
        console.log('onProgress', `${percent}%`, file.name);
      },
      onError(err) {
        console.log('onError', err);
      },
      customRequest({
        action,
        file,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
      }) {
        // 使用 FileReader 将上传的文件转换成二进制流，满足 'application/octet-stream' 格式的要求
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        // let multipartFile = null;
        reader.onload = (e) => {
          // 在文件读取结束后执行的操作
        //   multipartFile = e.target.result;
          // 使用 axios 进行文件上传的请求
          sxAjax.formdata(action, {multipartFile:file,...uploadParam}).then(response => {
              onSuccess(response, file);
            })
            .catch(onError);
        };
        return {
          abort() {
            console.log('upload progress is aborted.');
          },
        };
      },
    };
    return (
      <div style={this.props.style}>
        <Upload {...uploadProps}>
          {renderButton}
        </Upload>
      </div>
    );
  }
}