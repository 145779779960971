import React from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'antd';
import classNames from 'classnames';
import './style.less';
import {connect} from '@/models/index';
import fico from '../../../favicon.ico'
@connect((state)=>{
    const {newCount,msgs} = state.customer;
    return{
        newCount,
        msgs
    }
})
export default class SlTab extends React.Component {
    static propTypes = {
        type: PropTypes.any,          // 按钮的类型
        title: PropTypes.any,          // 按钮的title
        changeTab: PropTypes.func,  // 点击事件,
        crtTab: PropTypes.any,//当前crttab
    };
    changeTab(item){
        this.setState({
            isRead:true
        })
        this.props.action.customer.setNewCount(0);
        this.props.action.customer.setIsNewMsg(false);
        let $favicon = document.querySelector('link');
        $favicon.type = 'image/x-icon'
        $favicon.rel = 'shortcut icon';
        $favicon.href = fico;
        const {changeTab}=this.props
        if(changeTab){
            changeTab(item.value)
        }
    }
    renderItem(data,crtTab){
        var items=[]
        data.map((item,index)=>{
            items.push(
                <div key={index} onClick={()=>{this.changeTab(item)}} className={classNames('sl-tab-item',{'active':item.value===crtTab})}>
                    {/* {this.props.newCount>0 && index===0? 
                    <Badge count={this.props.newCount} offset={[5,-5]} overflowCount="99">
                    {item.title}
                    </Badge>:<span>{item.title}</span>} */}
                    <span>{item.title}</span>
                </div>
            )
        })
        return items
    }
    render() {
        const {
            className,
            data,
            crtTab,
        } = this.props;
        return (
           <div className="sl-tab-warp">
               {this.renderItem(data,crtTab)}
           </div>
        );
    }
}
