// 此文件为生成文件，不要直接编辑
import _customer from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/customer.js';
import _department from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/department.js';
import _menu from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/menu.js';
import _page from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/page.js';
import _report from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/report.js';
import _settings from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/settings.js';
import _side from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/side.js';
import _system from '/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/models/system.js';

export const customer = _customer;
export const department = _department;
export const menu = _menu;
export const page = _page;
export const report = _report;
export const settings = _settings;
export const side = _side;
export const system = _system;

