exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3mNnE69PRk0A9Jh1XpLA_Z {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD {\n  width: 100%;\n  max-width: 350px;\n  height: 240px;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM {\n  width: 240px;\n  height: 240px;\n  margin: 0 auto;\n  background-color: rgba(255, 255, 255, 0.6);\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM ._3vGPUJuBa8CnNuvjWyI5gO {\n  background-color: #f7f8fa;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  position: relative;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM ._3vGPUJuBa8CnNuvjWyI5gO .tQbKraWTNiGnPbzvuEmJz {\n  width: 160px;\n  height: 160px;\n  margin: 0 auto;\n  overflow: visible;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM ._3vGPUJuBa8CnNuvjWyI5gO .tQbKraWTNiGnPbzvuEmJz ._3ELdbimAn6J9lGEHm5eUdv {\n  width: 240px;\n  height: 240px;\n  background-color: rgba(255, 255, 255, 0.6);\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 2;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM ._3vGPUJuBa8CnNuvjWyI5gO .tQbKraWTNiGnPbzvuEmJz ._3TpGGuN__kjsSVTgxY_xo7 {\n  display: flex;\n  width: 160px;\n  height: 160px;\n  position: absolute;\n  overflow: hidden;\n  z-index: 3;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM ._3vGPUJuBa8CnNuvjWyI5gO .tQbKraWTNiGnPbzvuEmJz ._3vODIANk12t2qDoep8Hh9b {\n  width: 160px;\n  height: 160px;\n  z-index: 1;\n  position: relative;\n  top: 0;\n  left: 0;\n  overflow: visible;\n}\n._3mNnE69PRk0A9Jh1XpLA_Z ._3Qoq6TjsW8NxagSlFUYnYD .KyrS26WEVNprEbyGS0TGM ._3vGPUJuBa8CnNuvjWyI5gO .tQbKraWTNiGnPbzvuEmJz ._3vODIANk12t2qDoep8Hh9b img {\n  margin: 0;\n  padding: 0;\n}\n", ""]);

// exports
exports.locals = {
	"model_wrap_sin": "_3mNnE69PRk0A9Jh1XpLA_Z",
	"model_wrap_cox": "_3Qoq6TjsW8NxagSlFUYnYD",
	"model_wrap_cox_cen": "KyrS26WEVNprEbyGS0TGM",
	"model_wrap_cox_ast": "_3vGPUJuBa8CnNuvjWyI5gO",
	"model_wrap_liz": "tQbKraWTNiGnPbzvuEmJz",
	"model_wrap_mas": "_3ELdbimAn6J9lGEHm5eUdv",
	"model_wrap_lig": "_3TpGGuN__kjsSVTgxY_xo7",
	"model_wrap_ligt": "_3vODIANk12t2qDoep8Hh9b"
};