import Mock from 'mockjs';

const random = Mock.Random;
const LoginUsers = [
    {
        id: '5754195b570b367345584998',
        name: '超级管理员',
        loginName: 'admin',
        gender: 'male',
        org_key: '1464444570801',
        roleId: '5753f4706df8f6094bf3fc54',
        pass: '111111',
        salt: '03525b62-15aa-4ffe-a1ee-0385498e01f9',
        update_at: '2016-08-23T13:44:18.819Z',
        create_at: '2016-06-05T12:21:47.666Z',
        is_deleted: false,
        is_locked: false,
        is_first_login: false,
        position: 'CEO',
        mobile: '88888888888',
        email: 'admin@163.com',
        permissions: ['users-add', 'users-delete', 'users-update', 'users-search', 'users-reset-pass', 'users-toggle-lock', 'organization-add', 'organization-delete', 'organization-update', 'organization-search', 'role-add', 'role-update', 'role-delete', 'role-search', 'system', 'system-004002', 'system-004001', 'system-002'],
    },
    {
        id: '5754195b570b367345584998',
        name: '超级管理员',
        loginName: 'admin2',
        gender: 'male',
        org_key: '1464444570801',
        roleId: '5753f4706df8f6094bf3fc54',
        pass: '111111',
        salt: '03525b62-15aa-4ffe-a1ee-0385498e01f9',
        update_at: '2016-08-23T13:44:18.819Z',
        create_at: '2016-06-05T12:21:47.666Z',
        is_deleted: false,
        is_locked: false,
        is_first_login: false,
        position: 'CEO',
        mobile: '88888888888',
        email: 'admin@163.com',
        permissions: ['users-add', 'users-delete', 'users-update', 'users-search', 'users-reset-pass', 'users-toggle-lock', 'organization-add', 'organization-delete', 'organization-update', 'organization-search', 'role-add', 'role-update', 'role-delete', 'role-search', 'system', 'system-004002', 'system-004001', 'system-002'],
    },
];

export function getCustomersByPageSize(pageSize) {
    const customers = [];
    for (let i = 0; i < pageSize; i++) {
        customers.push(Mock.mock({
            id: random.guid(),
            code:random.character('upper'),
            IDP: random.string(4),
            name:random.cname(),
            customerNum: random.integer( 12, 10000 ),
            'totalMoney|1': ['2200万', '5030万','1090万', '2830万'],
            date:random.date(),
            address: Mock.mock('@county(true)'),
            'size|1': ['50㎡以下', '100㎡','200㎡', '150㎡'],
            mobile: '18611438888',
            'dep|1': ['销售一部', '销售二部','销售三部', '销售四部'],
            'group|1': ['一组', '二组'],
            'statue|1': ['已面洽', '待沟通'],
            
           
        }));
    }
    return customers;
}

export {LoginUsers};
