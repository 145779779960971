exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3eCNxch45yQJirt1KGThUu {\n  width: 100%;\n  max-width: 350px;\n  margin: 0 auto;\n}\n._2ouVYpASRRcICszLLZ8Juw {\n  width: 100%;\n  max-width: 350px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: -100px;\n}\n", ""]);

// exports
exports.locals = {
	"model_wrap": "_3eCNxch45yQJirt1KGThUu",
	"model_wrap_modal": "_2ouVYpASRRcICszLLZ8Juw"
};