import React, { Component, Fragment } from "react";
import { Button, Spin } from "antd";
import PropTypes from "prop-types";
import { getElementTop, getParentByClassName } from "@/library/utils";

/**
 * Modal 的内容容器，默认会使用屏幕剩余空间，内部内容滚动
 */
export default class ModalContent extends Component {
  state = {
    height: "auto",
  };

  static propTypes = {
    surplusSpace: PropTypes.bool, // 是否使用屏幕剩余空间
    loading: PropTypes.bool, // 是否加载中
    loadingTip: PropTypes.any, // 加载中提示文案
    otherHeight: PropTypes.number, // 除了主体内容之外的其他高度，用于计算主体高度；
    footer: PropTypes.any, // 底部
    moreFooter: PropTypes.any, // 底部
    okText: PropTypes.any, // 确定按钮文案
    resetText: PropTypes.any, // 重置按钮文案
    cancelText: PropTypes.any, // 取消按钮文案
    onOk: PropTypes.func, // 确定事件
    onCancel: PropTypes.func, // 取消事件
    onReset: PropTypes.func, // 表单重置事件
    style: PropTypes.object, // 最外层容器样式
    bodyStyle: PropTypes.object, // 内容容器样式,
    isInfo: PropTypes.bool, // 是否用于固定info样式
  };

  static defaultProps = {
    loading: false,
    style: {top:"100px"},
    bodyStyle: {},
    surplusSpace: true,
    okText: "确定",
    resetText: "重置",
    cancelText: "取消",
    width: "800px",
    isInfo: false,
    onOk: () => void 0,
    onCancel: () => void 0,
  };

  componentDidMount() {
    const { surplusSpace } = this.props;
    if (surplusSpace) {
      this.handleWindowResize();
      window.addEventListener("resize", this.handleWindowResize);
    }
  }

  componentWillUnmount() {
    const { surplusSpace } = this.props;
    if (surplusSpace)
      window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    let { otherHeight, isInfo } = this.props;
    const windowHeight = document.documentElement.clientHeight;
    if (!otherHeight) {
      const top = getElementTop(this.wrapper);
      let bottom = 24;
      const antModalDom = getParentByClassName(this.wrapper, "ant-modal");

      if (antModalDom) {
        const classList = Array.from(antModalDom.classList);
        const isFullScreen = classList.find((item) =>
          item.startsWith("full-screen")
        );

        if (isFullScreen) bottom = 0;
      }

      otherHeight = top + bottom;
    }
    const height = windowHeight - otherHeight;

    this.setState({ height: "auto"});
  };

  render() {
    const {
      surplusSpace,
      loading,
      loadingTip,
      otherHeight,
      style,
      bodyStyle,
      footer,
      moreFooter=true,
      okText,
      resetText,
      cancelText,
      onOk,
      onCancel,
      onReset,
      children,
      isInfo,
      onCustFun,
      custTools,
      custText,
      ...others
    } = this.props;
    const { height } = this.state;
    let isAuto = surplusSpace ? "auto" : "";
    isAuto = isInfo ? "" : isAuto;
    return (
      <Spin spinning={loading} tip={loadingTip}>
        <div
          className="modal-content"
          ref={(node) => (this.wrapper = node)}
          style={{ display: "flex", flexDirection: "column", height, ...style }}
          {...others}
        >
          <div
            style={{
              flex: isInfo ? "" : 1,
              padding: 16,
              paddingBottom: isInfo ? 0 : 16,
              overflow: isAuto,
              ...bodyStyle,
            }}
          >
            {children}
          </div>
          {footer !== false ? (
            <div
              className="ant-modal-footer"
              style={{
                flex: 0,
                borderWidth: isInfo ? 0 : 1,
                paddingTop: isInfo ? 0 : 16,
              }}
            >{custTools!=null?
              <div style={{ display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent:"center", marginTop: 30}}>
             { custTools}
              </div>:(footer &&  footer!==true? footer:moreFooter? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {moreFooter}
                  <div style={{ marginTop: 30 }}>
                    <Button type="primary" onClick={onOk}>
                      {okText}
                    </Button>
                    {onReset ? (
                      <Button onClick={onReset}>{resetText}</Button>
                    ) : null}
                    {onCustFun ? (
                      <Button type="primary" onClick={onCustFun}>
                        {custText}
                      </Button>
                    ) : null}
                    <Button onClick={onCancel}>{cancelText}</Button>
                  </div>
                </div>
              ):(
                <Fragment>
                  <Button type="primary" onClick={onOk}>
                    {okText}
                  </Button>
                  {onReset ? (
                    <Button onClick={onReset}>{resetText}</Button>
                  ) : null}
                  {onCustFun ? (
                    <Button type="primary" onClick={onCustFun}>
                      {custText}
                    </Button>
                  ) : null}
                  <Button onClick={onCancel}>{cancelText}</Button>
                </Fragment>
              )
              )
              
            }
              
            </div>
          ) : null}
        </div>
      </Spin>
    );
  }
}
