import React, { Component } from "react";
import { Menu, Dropdown, Icon, Badge, message } from "antd";
import { Link } from "react-router-dom";
import { toLogin, getLoginUser } from "@/commons";
import config from "@/commons/config-hoc";
import "./style.less";
import msg from "../../assets/icon/msg_center.png";
import { connect } from "@/models/index";
import MsgList from "../../pages/msg/index";
@config({ ajax: true })
@connect((state) => {
  const { newCount, msgs, isNewMsg } = state.customer;
  return {
    newCount,
    msgs,
    isNewMsg,
  };
})
export default class HeaderMsg extends Component {
  static defaultProps = {
    theme: "default",
  };

  state = {
    passwordVisible: false,
    isRed: false,
    msgVisible: false,
  };
  onRef = (ref) => {
      this.msgPop = ref
  }

  search = (e) => {
      this.msgPop.search(1)
  }

  handleMenuClick = ({ key }) => {
    if (key === "logout") {
      //退出登录
      this.props.ajax
        .get(`/logout/${getLoginUser().id}`)
        .then(() => {
          toLogin();
        })
        .finally();
    }

    if (key === "modifyPassword") {
      this.setState({ passwordVisible: true });
    }
  };
  renderMsgList = (list) => {
    var items = [];
    list.forEach((msg) => {
      items.push(
        <div styleName="msg-line">
          <div styleName="msg-top">
            <div styleName="msg-title">{msg.title}</div>
            <div styleName="msg-time">
              {this.$dayjs(msg.sendTime).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
          <div styleName="msg-content">{msg.content}</div>
        </div>
      );
    });
    return items;
  };

  render() {
    const user = getLoginUser() || {};
    const name = user.name;

    const { className, theme, newCount, isNewMsg, msgs,history } = this.props;

    const msgList = <div styleName="msg-box">{this.renderMsgList(msgs)}</div>;
    return (
      <div styleName="user-menu" ref={(node) => (this.userMenu = node)}>
        {/* <Dropdown
          overlay={msgList}
          placement="bottomCenter"
          getPopupContainer={() => this.userMenu || document.body}
         
          onVisibleChange={(visible) => {
            if (!visible) {
              this.props.action.customer.setIsNewMsg(false);
            }
          }}
        >
          <span
            styleName="account"
            className={className}
            style={{ paddingRight: 20 }}
          >
            {isNewMsg ? (
              <Badge dot={true}>
                <img src={msg} width="20px" alt="" />
              </Badge>
            ) : (
              <img src={msg} width="20px" alt="" onClick={()=>this.setState({
                msgVisible:true
              })}/>
            )}
          </span>
        </Dropdown> */}
        <span
          styleName="account"
          className={className}
          style={{ paddingRight: 20 }}
          onClick={() => {
            this.search();
            this.setState({
              msgVisible: true,
            });
            this.props.action.customer.setIsNewMsg(false);
           
          }}
        >
          {isNewMsg ? (
            <Badge dot={true}>
              <img src={msg} width="20px" alt="" />
            </Badge>
          ) : (
            <img
              src={msg}
              width="20px"
              alt=""
              
            />
          )}
        </span>
        <MsgList
          visible={this.state.msgVisible}
          onRef={this.onRef}
          history={history}
          name={name}
          onOk={() => this.setState({ msgVisible: false })}
          onClose={() => this.setState({ msgVisible: false })}
        />

        {/* <ModifyPassword
                    visible={this.state.passwordVisible}
                    onOk={() => this.setState({passwordVisible: false})}
                    onCancel={() => this.setState({passwordVisible: false})}
                /> */}
      </div>
    );
  }
}
