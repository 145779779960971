import React from 'react';
import {Icon} from 'antd';
import './style.less';

export default function (props) {
    return (
        <div styleName="footer" {...props}>
            Copyright <Icon type="copyright"/><a href='https://beian.miit.gov.cn' style={{color:'#333'}}>浙ICP备2022021996号-1</a>
        </div>
    );
}
