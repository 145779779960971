import { sxAjax } from "@/commons/ajax";
import { convertToTree } from "@/library/utils/tree-utils";
import { groupArr,sortKey } from "@/library/utils";
const setItem = (key, value) => window.localStorage.setItem(key, value);
export default {
  initialState: {
    title: void 0,
    depOrgs: [{ value: 0, label: "全部" }],
    depGroups: [{ value: 0, label: "全部" }],
    depUserList: [{ value: 0, label: "全部" }],
    treeDeps: [],
    deps: [],
  },
  setDeps: (deps) => ({ deps }),
  setTreeDeps: (treeDeps) => ({ treeDeps }),
  setDepOrgs: (depOrgs) => ({ depOrgs }),
  setDepGroups: (depGroups) => ({ depGroups }),
  setDepUserList: (depUserList) => ({ depUserList }),
  //获取组织架构
  getDeps: {
    payload: ({ params, options } = {}) =>
      sxAjax.list("/department/all", params, options),
    reducer: {
      resolve: (state, { payload: res }) => {
        console.log(res);
        res.records.forEach(dep => {
          dep.parentId=dep.id==='30'?null:dep.parentId
        });
        const groups = groupArr(sortKey(res?.records,"sort",true), "parentId");
        const deps = res?.records.map((dep) => {
          dep["key"] = dep.id;
          dep["parentKey"] = dep.parentId;
          dep["title"] = dep.name;
          dep["value"] = dep.id;
          dep["groups"] =
            groups.find((group) => {
              return group.by === dep.id;
            })?.groups || null;
          return dep;
        });
        console.log(deps);
        // deps=sortKey(deps,'name',true)
        const treeDeps = convertToTree(deps);
        console.log(treeDeps);
        setItem("treeDeps", null);
        setItem("treeDeps", JSON.stringify(treeDeps || []));
        return { treeDeps: treeDeps, deps: deps };
      },
    },
  },
  //获取部门
  getDepOrgs: {
    payload: ({ params } = {}) => sxAjax.get("/get/department"),
    reducer: {
      resolve: (state, { payload: res }) => {
        const reslist = [{ value: 0, label: "全部" }].concat(
          res.map((item) => {
            return { value: item.id, label: item.name };
          }) || []
        );
        return { depOrgs: reslist };
      },
    },
  },
  //根据部门获取小组
  getDepGroups: {
    payload: ({ params } = {}) => sxAjax.get("/get/group?id=" + params.org),
    reducer: {
      resolve: (state, { payload: res }) => {
        const reslist = [{ value: 0, label: "全部" }].concat(
          res.map((item) => {
            return { value: item.id, label: item.name };
          }) || []
        );
        return { depGroups: reslist };
      },
    },
  },
  //根据小组获取业务员
  getDepUserList: {
    payload: ({ params } = {}) =>
      sxAjax.get(
        "/get/salesman?id=" + (params.depId > 0 ? params.depId : params.org)
      ),
    reducer: {
      resolve: (state, { payload: res }) => {
        // console.log(res)
        const reslist = [{ value: 0, label: "全部" }].concat(
          res.map((item) => {
            return { value: item.id, label: item.name };
          }) || []
        );
        return { depUserList: reslist };
      },
    },
  },
};
