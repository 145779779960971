export default {
    ROOT:"root",//系统用户
    /**
  * 业务员
  */
 SALE_MAN:"sale_man",
 /**
  * 业务经理
  */
BUSINESS_MANAGER:"business_manager",
 /**
  *财务总监
  */
 FINANCE_MANAGER:"finance_manager",
 /**
  * 会计
  */
 ACCOUNTANT:"accountant",
 /**
  * 人事经理
  */
 HR_MANAGER:"hr_manager",
  /**
  * 销售行政总监
  */
  SALES_DIRECTOR:"sales_director",
 /**
  * 权证部经理
  */
 WARRANT_MANAGER:"warrant_manager",
 /**
  * 权证专员
  */
 WARRANT_COMMISSIONER:"warrant_commissioner",
 /**
  * 前台
  */
 RECEPTION:"reception",
 /**
  * 后勤部经理
  */
 LOGISTIC_MANAGER:"logistic_manager",
 /**
  * 销售部经理
  */
 SALE_MANAGER:"sale_ manager",
 /**
  * 运营专员
  */
 OPERATION_COMMISSIONER:"operation_commissioner",
 /**
  * 运营部门经理
  */
 OPERATION_MANAGER:"OPERATION_MANAGER",
 /**
  * 总经理
  */
 GENERAL_MANAGER:"GENERAL_MANAGER"
}