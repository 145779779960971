// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/channel_info/:id',
    '/login',
    '/login1',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/channel_info/:id',
    '/login',
    '/login1',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/channel_info/:id',
        keepAlive: false,
    },
    {
        path: '/Channel',
        keepAlive: true,
    },
    {
        path: '/contract_manager',
        keepAlive: true,
    },
    {
        path: '/customer/blacklist',
        keepAlive: true,
    },
    {
        path: '/customer:key',
        keepAlive: true,
    },
    {
        path: '/customer/setting',
        keepAlive: true,
    },
    {
        path: '/channel-data',
        keepAlive: true,
    },
    {
        path: '/dkt/banner',
        keepAlive: true,
    },
    {
        path: '/dkt/config',
        keepAlive: true,
    },
    {
        path: '/dkt/goodsReport',
        keepAlive: true,
    },
    {
        path: '/dkt/news',
        keepAlive: true,
    },
    {
        path: '/dkt/user',
        keepAlive: true,
    },
    {
        path: '/dkt/user/_/info/:id/:username',
        keepAlive: true,
    },
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/org/job',
        keepAlive: true,
    },
    {
        path: '/kpi/communication/report/_/edit/:id',
        keepAlive: true,
    },
    {
        path: '/kpi/list',
        keepAlive: true,
    },
    {
        path: '/kpi/performance',
        keepAlive: true,
    },
    {
        path: '/kpi/target',
        keepAlive: true,
    },
    {
        path: '/kpi/workreport',
        keepAlive: true,
    },
    {
        path: '/login',
        keepAlive: false,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/person_info',
        keepAlive: false,
    },
    {
        path: '/organization:key',
        keepAlive: true,
    },
    {
        path: '/sys/config',
        keepAlive: true,
    },
];

// 页面路由配置
export default [
    {
        path: '/channel_info/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/channel/Edit.jsx'),
    },
    {
        path: '/Channel',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/channel/index.jsx'),
    },
    {
        path: '/Channel/_/info/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/channel/Info.jsx'),
    },
    {
        path: '/channel/recordInfo',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/channel/RecordInfo.jsx'),
    },
    {
        path: '/contract/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/contract/Edit.jsx'),
    },
    {
        path: '/contract_manager',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/contract/index.jsx'),
    },
    {
        path: '/customer/blacklist/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/customer/blacklist/Edit.jsx'),
    },
    {
        path: '/customer/blacklist',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/customer/blacklist/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/customer/Edit.jsx'),
    },
    {
        path: '/customer:key',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/customer/index.jsx'),
    },
    {
        path: '/customer/setting/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/customer/setting/Edit.jsx'),
    },
    {
        path: '/customer/setting',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/customer/setting/index.jsx'),
    },
    {
        path: '/dailyChannel/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dailyChannel/Edit.jsx'),
    },
    {
        path: '/channel-data',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dailyChannel/index.jsx'),
    },
    {
        path: '/department/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/department/Edit.jsx'),
    },
    {
        path: '/department',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/department/index.jsx'),
    },
    {
        path: '/dkt/banner/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/banner/Edit.jsx'),
    },
    {
        path: '/dkt/banner',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/banner/index.jsx'),
    },
    {
        path: '/dkt/config/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/config/Edit.jsx'),
    },
    {
        path: '/dkt/config',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/config/index.jsx'),
    },
    {
        path: '/dkt/goodsReport/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/goodsReport/Edit.jsx'),
    },
    {
        path: '/dkt/goodsReport',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/goodsReport/index.jsx'),
    },
    {
        path: '/dkt/news/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/news/Edit.jsx'),
    },
    {
        path: '/dkt/news',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/news/index.jsx'),
    },
    {
        path: '/dkt/_/config',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/user/Edit.jsx'),
    },
    {
        path: '/dkt/user',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/user/index.jsx'),
    },
    {
        path: '/dkt/user/_/info/:id/:username',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/dkt/user/Info.jsx'),
    },
    {
        path: '/example/ajax',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/iframe/index.jsx'),
    },
    {
        path: '/org/job',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/job/index.jsx'),
    },
    {
        path: '/kpi/communication/report/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/communication/report/Edit.jsx'),
    },
    {
        path: '/kpi/communication/report',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/communication/report/index.jsx'),
    },
    {
        path: '/kpi/list',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/index.jsx'),
    },
    {
        path: '/kpi/performance/_/edit/:id/:userId/:page/:state/:performanceName',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/performance/Edit.jsx'),
    },
    {
        path: '/kpi/performance',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/performance/index.jsx'),
    },
    {
        path: '/kpi/target',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/target/index.jsx'),
    },
    {
        path: '/kpi/workreport/_/edit/:id:userId',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/workreport/daily/Edit.jsx'),
    },
    {
        path: '/kpi/workreport',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/workreport/index.jsx'),
    },
    {
        path: '/kpi/workreport/_/edit/motnly/:id:userId',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/kpi/workreport/monthly/Edit.jsx'),
    },
    {
        path: '/login',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/login/index.jsx'),
    },
    {
        path: '/login1',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/login1/index.jsx'),
    },
    {
        path: '/sys/menu',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/person_info',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/min/index.jsx'),
    },
    {
        path: '/msg',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/msg/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/organization/Edit.jsx'),
    },
    {
        path: '/organization:key',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/organization/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/setting/index.jsx'),
    },
    {
        path: '/sl-authority/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/sl-authority/Edit.jsx'),
    },
    {
        path: '/sl-authority',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/sl-authority/index.jsx'),
    },
    {
        path: '/slRole/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/slRole/Edit.jsx'),
    },
    {
        path: '/sl-role',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/slRole/index.jsx'),
    },
    {
        path: '/dkt/config/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/sys/config/Edit.jsx'),
    },
    {
        path: '/sys/config',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/sys/config/index.jsx'),
    },
    {
        path: '/sys/doc/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/sys/doc/Edit.jsx'),
    },
    {
        path: '/sys/doc',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/sys/doc/index.jsx'),
    },
    {
        path: '/user-center',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/user-center/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/users/Edit.jsx'),
    },
    {
        path: '/users',
        component: () => import('/Users/lmr/Documents/workspace/zq/zq2.0/zl-admin/src/pages/users/index.jsx'),
    },
];
