/*
 * 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
 * */
import { ajax } from "@/commons/ajax";
import { removeNull } from "@/library/utils";
import { flatTree,convertToTree } from "@/library/utils/tree-utils";
import { getLoginUser} from "@/commons";
export default function getMenus(userId, permissions, isall) {
  // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
  const NOT_IN_LEFT = [];
    let otherMenu=[{"id":"46","text":"任务中心","code":"JOB_CENTER","level":0,"parentKey":"SYS","parentId":null,"state":null,"icon":"square","path":null,"target":null,"url":"http://106.14.21.117:7001/xxl-job-admin/toLogin","sort":798}]
    if([4,5].includes(getLoginUser()?.roleId)){
      otherMenu=getLoginUser()?.resource
    }else{
      otherMenu=getLoginUser()?.resource
    }
    const menus =
    flatTree(otherMenu || []) ||
      [
        // {key: 'SYS', text: '系统设置', icon: 'icon_setting_no',order:800},
        // {key: 'SYS_DOC', parentKey: 'SYS',text: '档案管理',icon:'square', path: '/sys/doc', order:799},
        // {key: 'ORG', text: '组织架构', icon: 'icon_org_no',order:901},
        // {key: 'DEPARTMENT', parentKey: 'ORG',text: '部门管理',icon:'square', path: '/department', order: 900},
        // {key: 'USER', parentKey: 'ORG',text: '用户列表',icon:'square', path: '/organizationuser', order: 899},
        // {key: 'ORG_05', parentKey: 'ORG', text: '销售一部',icon:'square',path: '/organization1-1', order: 893},
        // {key: 'ORG_05_01', parentKey: 'ORG_05', text: '一组',icon:'square',path: '/organization1-1-1', order: 889},
        // {key: 'ORG_05_02', parentKey: 'ORG_05', text: '二组',icon:'square', path: '/organization1-1-2',order: 888},
        // {key: 'AUTH', text: '权限管理', icon: 'icon_auth_no', order: 2000},
        // {key: 'AUTH_ROLE', text: '角色管理', parentKey: 'AUTH', icon: 'square', path: '/sl-role', order: 2001},
        // {key: 'AUTH_AUTHORITY', text: '权限资源',parentKey: 'AUTH', icon: 'square', path: '/sl-authority', order: 2002},
        // {key: 'OT_MANAGE', text: '运营管理', icon: 'icon_op_no',order:1005},
        // {key: 'OT_CHANNEL', text: '渠道管理', icon:'square',path: '/channel',parentKey:'OT_MANAGE', order: 1004},
        // {key: 'OT_CHANNEL_DATA', text: '渠道报表',icon:'square',path: '/channel-data',parentKey:'OT_MANAGE',order:1003},
        // {key: 'CUSTOMER', text: '客户管理', icon: 'icon_customer_no', order: 999,path:'/customer'},
        // {key: 'PERFORMANCE', text: '绩效统计', icon: 'icon_kpi_no',url:'http://192.168.1.163:8080/xxl-job-admin/jobinfo',order: 996},
      ];
    const userMenus = 
        menus.filter((item) => {
            return (
              !NOT_IN_LEFT.includes(item.code)
            );
          })
        ;
    userMenus.forEach(um => {
      delete um.children
      um['key']=um.code
      um.order=um.sort
    });
    console.log(userMenus)
    return Promise.resolve(userMenus);
}
