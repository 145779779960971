import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import moment from 'moment';
import LunarCalendar from "lunar-calendar";
import { getLoginUser, DOC, isSalemen ,AUTH} from "@/commons";

/**
 * 复合函数工具
 * @param functions
 * @returns {*}
 */
export function compose(functions) {
  if (functions.length === 0) {
    return (arg) => arg;
  }

  if (functions.length === 1) {
    return functions[0];
  }

  return functions.reduce((a, b) => (...args) => a(b(...args)));
}

/**
 * 加载 JavaScript
 * @param src
 * @returns {Promise<any>}
 */
export function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

/**
 * 判断字符串是否符合json各式
 * @param str
 * @returns {boolean}
 */
export function isJson(str) {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  } catch (e) {
    return false;
  }
}

/**
 * 通用的一些工具方法
 * @module 通用工具方法
 * */

/**
 * 字符串中所有单词首字母大写
 * @param {String} str
 * @returns {String}
 */
export function firstUpperCase(str) {
  const s = typeof str !== "string" ? `${str}` : str;
  return s.replace(/\b(\w)(\w*)/g, ($0, $1, $2) => $1.toUpperCase() + $2);
}

/**
 * 字符串中所有单词首字母小写
 * @param {String} str
 * @returns {String}
 */
export function firstLowerCase(str) {
  const s = typeof str !== "string" ? `${str}` : str;
  return s.replace(/\b(\w)(\w*)/g, ($0, $1, $2) => $1.toLowerCase() + $2);
}

/**
 * 获取字符串字节长度，中文占两个字节
 * @param {String} value
 * @returns {number}
 */
export function getStringByteLength(value) {
  if (!value) return 0;
  const s = typeof value !== "string" ? `${value}` : value;
  let length = s.length;

  for (let i = 0; i < s.length; i++) {
    if (s.charCodeAt(i) > 127) {
      length++;
    }
  }

  return length;
}

/**
 * 格式化字符串
 * @example
 * stringFormat('H{0}llo W{1}rld!', 'e', 'o');
 * stringFormat('H{eKey}llo W{oKey}rld!', {eKey: 'e', oKey: 'o'});
 * @param {String} value 需要格式化的字符串
 * @param {*} args 对象或者多个参数
 * @returns {*}
 */
export function stringFormat(value, ...args) {
  if (!value) return value;
  if (typeof value !== "string") return value;
  if (!args || !args.length) return value;

  if (args.length === 1 && typeof args[0] === "object") {
    const arg = args[0];
    Object.keys(arg).forEach((key) => {
      if (arg[key] !== undefined) {
        const reg = new RegExp(`({${key}})`, "g");
        value = value.replace(reg, arg[key]);
      }
    });
    return value;
  }

  for (let i = 0; i < args.length; i++) {
    if (args[i] !== undefined) {
      let reg = new RegExp(`({)${i}(})`, "g");
      value = value.replace(reg, args[i]);
    }
  }
  return value;
}

/**
 * 获取cookie
 * @param {String} objName 存储coolie中数据的key
 * @returns {String}
 */
export function getCookie(objName) {
  let arrStr = document.cookie.split("; ");
  for (let i = 0; i < arrStr.length; i++) {
    let temp = arrStr[i].split("=");
    if (temp[0] === objName) return unescape(temp[1]);
  }
  return "";
}

/**
 * 获取浏览器
 * @returns {number}
 */
export function getScrollBarWidth() {
  let scrollDiv = document.createElement("div");
  scrollDiv.style.position = "absolute";
  scrollDiv.style.top = "-9999px";
  scrollDiv.style.width = "50px";
  scrollDiv.style.height = "50px";
  scrollDiv.style.overflow = "scroll";
  document.body.appendChild(scrollDiv);
  let scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  window.document.body.removeChild(scrollDiv);
  return scrollBarWidth;
}

/**
 * 判断是否有滚动条
 * @param el
 * @param direction
 * @returns {boolean}
 */
export function hasScrollBar(el, direction = "vertical") {
  if (direction === "vertical") {
    return el.scrollHeight > el.clientHeight;
  } else if (direction === "horizontal") {
    return el.scrollWidth > el.clientWidth;
  }
}

/**
 * 获得一个指定范围内的随机数
 * @param {number} min 最范围
 * @param {number} max 最大范围
 * @returns {number}
 */
export function getRandomNum(min, max) {
  const range = max - min;
  const rand = Math.random();
  return min + Math.round(rand * range);
}

/**
 * 获取一个元素距离浏览器顶部高度
 * @param element
 * @returns {number | Requireable<number>}
 */
export function getElementTop(element) {
  let actualTop = element.offsetTop;
  let current = element.offsetParent;

  while (current !== null) {
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }

  return actualTop;
}

/**
 * 根据className 获取父级元素
 * @param el
 * @param parentClassName
 * @returns {*}
 */
export function getParentByClassName(el, parentClassName) {
  const parentNode = el.parentNode;
  if (!parentNode) return null;

  const classList = Array.from(parentNode.classList || []);

  if (classList?.length && classList.includes(parentClassName)) {
    return parentNode;
  } else {
    return getParentByClassName(parentNode, parentClassName);
  }
}

/**
 * 为一个dom元素移除class
 * @param {string} selector document.querySelectory 要到的选择器
 * @param {string} className 要移除的class
 */
export function removeClass(selector, className) {
  let doms = selector;
  if (typeof selector === "string") {
    doms = document.querySelectorAll(selector);
  }
  if (!doms || !doms.length) return;
  if (!doms.length) doms = [doms];
  doms.forEach((dom) => {
    let domClass = dom.className;
    if (domClass) {
      domClass = domClass.split(" ");
      if (!domClass || !domClass.length) return;
      dom.className = domClass.filter((c) => c !== className).join(" ");
    }
  });
}

/**
 * 为一个dom元素添加class
 * @param {string} selector document.querySelectory 要到的选择器
 * @param {string} className 要添加的class
 */
export function addClass(selector, className) {
  let doms = selector;
  if (typeof selector === "string") {
    doms = document.querySelectorAll(selector);
  }
  if (!doms || !doms.length) return;
  if (!doms.length) doms = [doms];
  doms.forEach((dom) => {
    let domClass = dom.className;
    if (domClass) {
      domClass = domClass.split(" ");
      if (!domClass || !domClass.length || domClass.indexOf(className) > -1)
        return;
      domClass.push(className);
      dom.className = domClass.join(" ");
    } else {
      dom.className = className;
    }
  });
}

/**
 * 拼接get请求所需url
 * @param {string} url
 * @param {object} params 请求参数
 * @returns {string} 拼接后的url
 */
export function mosaicUrl(url, params) {
  if (!params) return url;
  const queryString = [];
  Object.keys(params).forEach((key) => {
    let value = params[key];
    if (value !== undefined && value !== null) {
      queryString.push(`${key}=${value}`);
    }
  });
  const qStr = queryString.join("&");
  if (url.indexOf("?") < 0) {
    url += `?${qStr}`;
  } else if (url.endsWith("&")) {
    url += qStr;
  } else if (url.endsWith("?")) {
    url += `${qStr}`;
  } else {
    url += `&${qStr}`;
  }
  return url;
}

/**
 * 根据keyPath查找一个object中的数据
 * @param obj 需要查找的对象
 * @param {string} keyPath 类似： a.b.c
 * @returns {*} 查找到的数据
 */
function findObjByKeyPath(obj, keyPath) {
  const keys = keyPath.split(".");
  let targetObj = obj;
  keys.forEach((k) => {
    targetObj = targetObj[k];
  });
  return targetObj;
}

/**
 * 从数组中删除一个元素
 * @param {Array} arr 需要操作的数组
 * @param {*} item 要删除的元素，注意：内部是用'==='比对的
 */
export function arrayRemove(arr, item) {
  if (!arr || !Array.isArray(arr) || !arr.length) return arr;
  const newArr = cloneDeep(arr);
  let itemIndex = -1;
  for (let i = 0; i < newArr.length; i++) {
    if (newArr[i] === item) {
      itemIndex = i;
      break;
    }
  }
  if (itemIndex > -1) {
    newArr.splice(itemIndex, 1);
  }
  return newArr;
}

/**
 * 从数组中删除一些元素
 * @param {Array} arr 需要操作的数组
 * @param {Array} items 需要删除的元素
 */
export function arrayRemoveAll(arr, items) {
  if (!arr || !Array.isArray(arr) || !arr.length) return arr;
  if (!items || !Array.isArray(items) || !items.length) return arr;
  return arr.filter((item) => {
    return !items.find((it) => it === item);
  });
}

/**
 * 加入元素到数组中，如果已存在就不添加了
 * @param array
 * @param item
 * @returns {...*[]}
 */
export function arrayPush(array, item) {
  if (!array || !Array.isArray(array)) return array;

  const arr = [...array];
  if (!arr.includes(item)) arr.push(item);

  return arr;
}

/**
 * 根据指定keyPath 添加元素
 * @param obj 要操作的数据
 * @param {string} keyPath 类似于：a.b.c，就会把value赋值给c
 * @param {*} value 要设置的数据
 * @throws Will throw error if keyPath dose not point to an object
 */
export function objSetValue(obj, keyPath, value) {
  const newObj = cloneDeep(obj);
  const pointLastIndex = keyPath.lastIndexOf(".");
  if (pointLastIndex < 0) {
    if (typeof newObj !== "object") {
      throw new Error("keyPath dose not point to an object!");
    }
    newObj[keyPath] = value;
    return newObj;
  }
  const key = keyPath.substr(pointLastIndex + 1, keyPath.length);
  keyPath = keyPath.substr(0, pointLastIndex);
  let targetObj = findObjByKeyPath(newObj, keyPath);
  if (typeof targetObj !== "object") {
    throw new Error("keyPath dose not point to an object!");
  }
  targetObj[key] = value;
  return newObj;
}

/**
 * 根据keyPath定位到指定元素，并将其删除
 * @param obj 要操作的数据
 * @param {string} keyPath keyPath 类似于：a.b.c，会把c删除
 * @throws Will throw error if keyPath dose not point to an object
 */
export function objRemove(obj, keyPath) {
  const newObj = cloneDeep(obj);
  const pointLastIndex = keyPath.lastIndexOf(".");
  if (pointLastIndex < 0) {
    if (typeof newObj !== "object") {
      throw new Error("keyPath dose not point to an object!");
    }
    delete newObj[keyPath];
    return newObj;
  }
  const key = keyPath.substr(pointLastIndex + 1, keyPath.length);
  keyPath = keyPath.substr(0, pointLastIndex);
  let targetObj = findObjByKeyPath(newObj, keyPath);
  if (typeof targetObj !== "object") {
    throw new Error("keyPath dose not point to an object!");
  }
  delete targetObj[key];
  return newObj;
}

/**
 * 根据keyPath定位到指定数组，并添加元素
 * @param obj 要操作的数据
 * @param keyPath 类似于：a.b.c，通过keyPath，定位到obj中某个数组
 * @param value 需要append的元素
 * @throws Will throw error if keyPath dose not point to an array
 */
export function arrAppendValue(obj, keyPath, value) {
  const newObj = cloneDeep(obj);
  let targetObj = findObjByKeyPath(newObj, keyPath);
  if (!Array.isArray(targetObj)) {
    throw new Error("keyPath dose not point to an array!");
  }
  if (Array.isArray(value) && value.length) {
    value.forEach((v) => targetObj.push(v));
  } else {
    targetObj.push(value);
  }
  return newObj;
}

/**
 * 根据keyPath定位到指定数组，删除一个元素
 * @param obj 要操作的数据
 * @param keyPath 类似于：a.b.c，通过keyPath，定位到obj中某个数组
 * @param value 需要删除的数组元素
 * @throws Will throw error if keyPath dose not point to an array
 */
export function arrRemove(obj, keyPath, value) {
  const newObj = cloneDeep(obj);
  let targetObj = findObjByKeyPath(newObj, keyPath);
  if (!Array.isArray(targetObj)) {
    throw new Error("keyPath dose not point to an array!");
  }
  targetObj = arrayRemove(targetObj, value);
  return objSetValue(newObj, keyPath, targetObj);
}

/**
 * 根据keyPath定位到指定数组，删除所有跟value相同的元素
 * @param obj 要操作的数据
 * @param keyPath 类似于：a.b.c，通过keyPath，定位到obj中某个数组
 * @param value 移除的数组元素
 * @throws Will throw error if keyPath dose not point to an array
 */
export function arrRemoveAll(obj, keyPath, value) {
  const newObj = cloneDeep(obj);
  let targetObj = findObjByKeyPath(newObj, keyPath);
  if (!Array.isArray(targetObj)) {
    throw new Error("keyPath dose not point to an array!");
  }
  while (targetObj.indexOf(value) > -1) {
    targetObj = arrayRemove(targetObj, value);
  }
  return objSetValue(newObj, keyPath, targetObj);
}

/**
 * 数组去重，此方法不改变原有数据，返回新的数组
 * @param {Array} array
 * @returns {Array} 新数组
 */
export function uniqueArray(array) {
  const n = {}; // hash 表
  const r = []; // 临时数组
  for (let i = 0; i < array.length; i++) {
    // 遍历当前数组
    if (!n[array[i]]) {
      // 如果hash表中没有当前项
      n[array[i]] = true; // 存入hash表
      r.push(array[i]); // 把当前数组的当前项push到临时数组里面
    }
  }
  return r;
}

/**
 * 获取浏览器窗口大小
 * @returns {{width: (Number|number), height: (Number|number)}}
 */
export function getWindowSize() {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];
  const width = w.innerWidth || e.clientWidth || g.clientWidth;
  const height = w.innerHeight || e.clientHeight || g.clientHeight;
  return { width, height };
}

/**
 * 为dom元素添加事件
 * @param element {Object} 需要添加事件的dom元素
 * @param type {String} 事件名称，比如 'click'
 * @param handler {function} 事件处理函数
 */
export function addEventListener(element, type, handler) {
  if (!element) return;
  if (element.addEventListener) {
    element.addEventListener(type, handler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${type}`, handler);
  } else {
    element[`on${type}`] = handler;
  }
}

export function toLowerLine(str) {
  var temp = str.replace(/[A-Z]/g, function (match) {
    return "_" + match.toLowerCase();
  });
  if (temp.slice(0, 1) === "_") {
    //如果首字母是大写，执行replace时会多一个_，这里需要去掉
    temp = temp.slice(1);
  }
  return temp;
}

export function toCamel(str) {
  return str.replace(/([^_])(?:_+([^_]))/g, function ($0, $1, $2) {
    return $1 + $2.toUpperCase();
  });
}

/**
 * 为dom元素移除事件
 * @param element {Object} 需要添加事件的dom元素
 * @param type {String} 事件名称，比如 'click'
 * @param handler {function} 事件处理函数
 */
export function removeEventListener(element, type, handler) {
  if (!element) return;
  if (element.removeEventListener) {
    element.removeEventListener(type, handler, false);
  } else if (element.detachEvent) {
    element.detachEvent(`on${type}`, handler);
  } else {
    element[`on${type}`] = null;
  }
}

/**
 * 将数值四舍五入后格式化成金额形式
 *
 * @param num 数值(Number或者String)
 * @param options 可选参数
 * @param options.prefix 金钱前缀，默认为空，一般为 ￥ 或 $
 * @param options.decimalNum 保留小数点个数，默认为2 一般为 0 1 2
 * @param options.splitSymbol 格式化分割符，默认为英文逗号，分隔符必须是单字符
 * @return 金额格式的字符串,如'￥1,234,567.45'
 * @type String
 */
export function formatCurrency(num, options = {}) {
  let { decimalNum, splitSymbol } = options;
  const { prefix = "￥" } = options;
  let centsPercent = 100;
  if (splitSymbol === undefined) splitSymbol = ",";
  if (decimalNum !== 0 && decimalNum !== 1 && decimalNum !== 2) decimalNum = 2;
  if (decimalNum === 0) centsPercent = 1;
  if (decimalNum === 1) centsPercent = 10;
  num = num.toString().replace(/\$|,/g, "");
  if (isNaN(num)) num = "0";
  const sign = num === Math.abs(num).toString() ? "" : "~";
  num = Math.abs(num);
  num = Math.floor(num * centsPercent + 0.50000000001);
  let cents = num % centsPercent;
  num = Math.floor(num / centsPercent).toString();
  if (cents < 10 && decimalNum === 2) {
    cents = `0${cents}`;
  }
  for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    const endPosition = 4 * i + 3;
    num =
      num.substring(0, num.length - endPosition) +
      splitSymbol +
      num.substring(num.length - endPosition);
  }
  if (decimalNum === 0) {
    return prefix + sign + num;
  }
  return `${prefix}${sign}${num}.${cents}`;
}
export function getFixed(val, num) {
  val = (Math.round(val * 100) / 100).toFixed(num);
  let numStr = val.toString();
  let index = numStr.indexOf(".");
  let result = numStr.slice(0, index + num + 1);
  return Number(result);
}
/**
 * 排序
 * @param {*} array
 * @param {*} key
 */
export function sortKey(array, key,desc) {
  if(desc){
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    }).reverse();
  }
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });
}
// 下划线转换驼峰
export function toHump(name) {
  return name.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}
// 驼峰转换下划线
export function toLine(name) {
  return name.replace(/([A-Z])/g, "_$1").toLowerCase();
}
//数组分组
export function groupArr(list, field, groups) {
  var obj = {};
  for (var i = 0; i < list.length; i++) {
    for (const item in list[i]) {
      if (item === field) {
        obj[list[i][item]] = {
          groups: obj[list[i][field]] ? obj[list[i][field]].groups : [],
          by: list[i][field],
        };
      }
    }
    obj[list[i][field]].groups.push(list[i]);
  }
  var att = [];
  for (const item in obj) {
    att.push({
      groups: obj[item].groups,
      by: obj[item].by,
      ...list.find((li) => {
        return li.id === obj[item].by;
      }),
    });
  }
  return att;
}
/**
 * 去空属性
 * @param {*} array
 * @param {*} key
 */
export function removeNull(option) {
  if (!option) {
    return;
  }
  for (var attr in option) {
    if (option[attr] === null) {
      delete option[attr];
      continue;
    }
    if (typeof option[attr] == "object") {
      removeNull(option[attr]);
    }
  }
  return option;
}
/**
 * 比较两个对象不同
 */
export function getObjDifference(obj1, obj2) {
  var diff = {};
  for (const key in obj1) {
    if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
      diff[key] = obj2[key];
    }
  }
  for (const key in obj2) {
    if (!obj1.hasOwnProperty(key)) {
      diff[key] = obj2[key];
    }
  }
  return diff;
}

//数组分组
export function getParent(list, field, groups) {
  var newList = [];
  var resList = [];
  var parents = [];
  function findParent(cObj, list) {
    let res = cObj;
    let parent = list.find((i) => {
      return i.key === cObj[field];
    });
    if (parent) {
      parents.push(parent.key);
    }
    if (parent && parent[field]) {
      parent = findParent(parent, list);
    }
    res["parents"] = parent;
    return res;
  }
  list.forEach((o) => {
    var obj = { ...o };
    if (o.hasOwnProperty(field)) {
      //   console.log(o);
      obj = findParent(obj, list);
    }
    newList.push(obj);
  });
  newList.forEach((re) => {
    if (!parents.includes(re.key)) {
      resList.push(re);
    }
  });
  return resList;
}
export function intersection(a, b){
	const s = new Set(b);
	return a.filter(x => s.has(x));
};
// export function removeEl(val) { 
//   var index = this.indexOf(val); 
//   if (index > -1) { 
//   this.splice(index, 1); 
//   } 
//   };
export function isDateIntersection(start1, end1, start2, end2) {
  var startDate1=dayjs(start1)
  var endDate1=dayjs(end1)
  var startDate2=dayjs(start2)
  var endDate2=dayjs(end2)
  if (startDate1 >= startDate2 && startDate1 <= endDate2) {

      return true;
  }

  if (endDate1 >= startDate2 && endDate1 <= endDate2) {
      return true;
  }

  if (startDate1 <= startDate2 && endDate1 >= endDate2) {
      return true;
  }
  return false;
}
export function formatSeconds(value) {
  let result = parseInt(value)
  // let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 )) < 10 ? '0' + Math.floor((result / 60 )) : Math.floor((result / 60 ));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  // if(h !== '00') res += `${h}小时`;
  if(m !== '00') res += `${m}分`;
  res += `${s}秒`;
  return res;
}
export function formatWang(value) {
  value=value!=null?value:0;
  var k = 10000,
      sizes = ['', '万', '亿', '万亿'],
      i;
      if(value < k){
        return value
      }else{
          i = Math.floor(Math.log(value) / Math.log(k)); 
          return ((value / Math.pow(k, i))).toFixed(2)+sizes[i]
      }
}


export function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var endDate = moment(stopDate);
    while (currentDate <= endDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;}


//计算节气
export function getJQ(yyyy,mm,dd){
  var sTermInfo = [0,21208,42467,63836,85337,107014,128867,150921,173149,195551,218072,240693,263343,285989,308563,331033,353350,375494,397447,419210,440795,462224,483532,504758];
  var solarTerm = ["小寒","大寒","立春","雨水","惊蛰","春分","清明","谷雨","立夏","小满","芒种","夏至","小暑","大暑","立秋","处暑","白露","秋分","寒露","霜降","立冬","小雪","大雪","冬至"];
  var tmp1 = new Date((31556925974.7*(yyyy-1900)+sTermInfo[mm*2+1]*60000)+Date.UTC(1900,0,6,2,5));
  var tmp2 = tmp1.getUTCDate();
  var solarTerms = "";
  if (tmp2===dd)
    solarTerms = solarTerm[mm*2+1];
  tmp1 = new Date((31556925974.7*(yyyy-1900)+sTermInfo[mm*2]*60000)+Date.UTC(1900,0,6,2,5));
  tmp2= tmp1.getUTCDate();
  if (tmp2===dd)
    solarTerms = solarTerm[mm*2];
  return solarTerms;
 }
 export function sloarToLunar(sy, sm, sd) {
  let jqStr = getJQ(sy,sm,sd) // 获取节气信息
  let lsStr =LunarCalendar.solarToLunar(sy,sm+1,sd).lunarFestival ?   LunarCalendar.solarToLunar(sy,sm+1,sd).lunarFestival :  LunarCalendar.solarToLunar(sy,sm+1,sd).lunarDayName //获取农历以及节假日信息，并做优先级判断
  return LunarCalendar.solarToLunar(sy,sm+1,sd).GanZhiYear+(" ")+LunarCalendar.solarToLunar(sy,sm+1,sd).zodiac+(" ")+LunarCalendar.solarToLunar(sy,sm+1,sd).lunarMonthName+(" ")+(jqStr ?  jqStr : lsStr )
  
}
export function getNl(dateStr) {
  console.log(dayjs(dateStr).year())
  return sloarToLunar(dayjs(dateStr).year(),dayjs(dateStr).month(),dayjs(dateStr).date())
}
export function  formartPhone( phone) {
  return AUTH("business_manager_phone")?((phone+"").slice(0,3)+"****"+(phone+"").slice(7,11)):phone;
}
export function WEEK(num){
  let weeks=[];
  if(num<0){
    for (let index = 0; index >= num; index--) {
      weeks.push(getWeek(index));
    }
  }else{
    for (let index = 0; index <= num; index++) {
      weeks.push(getWeek(index));
    }
  }
  return weeks;
}
export function getWeek(num){
  let nowM=getMonday(dayjs());
 
  var start=num===0?dayjs(nowM).format('YYYY-MM-DD'):dayjs(nowM).subtract(num*7,'day');
  console.log(dayjs(start).format('YYYY-MM-DD'))
  var end=dayjs(start).add(6, 'day');
  console.log(dayjs(end).format('YYYY-MM-DD'))
  end.set('hour',23);
  end.set('minute',59);
  end.set('second',59);
  return {
    start:dayjs(start),
    end:end,
    startName:dayjs(start).format('YYYY-MM-DD'),
    endName:dayjs(end).format('YYYY-MM-DD'),
  }
}
export function getWeekByDate(date){
  let nowM=getMonday(dayjs(date));
 
  var end=dayjs(nowM).add(6, 'day');
  console.log(dayjs(end).format('YYYY-MM-DD'))
  end.set('hour',23);
  end.set('minute',59);
  end.set('second',59);
  return {
    start:dayjs(nowM),
    end:end,
    startName:dayjs(nowM).format('YYYY-MM-DD'),
    endName:dayjs(end).format('YYYY-MM-DD'),
  }
}
function getMonday(date){
  var day=dayjs().day();
  var deltaDay;
  if (day===0){
      deltaDay=6;
  } else{
      deltaDay=day-1;
  }
  var monday =dayjs(date).subtract(deltaDay,'day');
  monday.set('hour',0);
  monday.set('minute',0);
  monday.set('second',0);
  return monday;  //返回本周的周一的0时0分0秒
}
export function getMonthRange(date){
  date=dayjs(date).set('date',1);
 
  var month = parseInt(dayjs(date).get('month')+1);
  var end=new Date(dayjs(date).get('year'),month,0);
  end=dayjs(end);
  date.set('hour',0);
  date.set('minute',0);
  date.set('second',0);
  end.set('hour',23);
  end.set('minute',59);
  end.set('second',59);
 return{
   start:date,
   end:end,
   startName:dayjs(date).format('YYYY-MM-DD'),
   endName:dayjs(end).format('YYYY-MM-DD')
 }

}

export function MONTHS(num){
  let MONTHS=[];
  for (let index = 1; index <=num; index++) {
    var date=dayjs().subtract(1,'day');
    MONTHS.push(getMonthRange(date));
  }
  return MONTHS;

}
export function LASTMONTH(){
  let date=dayjs(dayjs()).set('date',1);
  return dayjs(dayjs(date).subtract(2,'day')).format('YYYY-MM-DD');
}
 
export function DFORMART(type){

  if(type==='day'){
    return "YYYY-MM-DD";
  }
  return type==="month"?"YYYY-MM":"YYYY-MM-DD dddd";

}