import React from "react";
import "./style.less";
import { List, Checkbox } from "antd";
export default function (props) {
  const { auths, allAuth, choeseFun, groupChoseFun } = props;
  function isChecked(id) {
    console.log(auths)
    return auths.includes(id);
  }
  const gettitle = (item) => {
    let title = item.title || item.text;
    if (item.parents) {
      title = (item.parents.title || item.parents.text) + ">" + title;
    }
    if (item.parents && item.parents.parents) {
      title = (item.parents.parents.title || item.parents.parents.text) + ">" + title;
    }
    return title;
  };
  function getGroup() {
    var groups = [];
    allAuth.forEach((item) => {
      groups.push(
        item.groups && item.groups.length > 0 ? (
          <div>
            <div className="auth-group">
              <Checkbox
                value={item.id}
                className="name-warp"
                onChange={(e) => groupChoseFun(e, item)}
                checked={isChecked(item.id)}
              >
                {gettitle(item)}
              </Checkbox>
            </div>
            <div className="auth-items-warp">
              <List
                grid={{
                  gutter: 16,
                  column:
                    item.groups.length < 5
                      ? item.groups.length
                      : item.groups.length === 5
                      ? 3
                      : 4,
                }}
                dataSource={item.groups}
                renderItem={(auth) => (
                  <List.Item className="auth-items">
                    <Checkbox
                      value={auth.id}
                      onChange={(e) => choeseFun(e, auth,item)}
                      checked={isChecked(auth)}
                      className="item-name-warp"
                    >
                      {auth.text || auth.note}
                    </Checkbox>
                  </List.Item>
                )}
              />
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="auth-group">
              <Checkbox
                value={item.key}
                className="name-warp"
                onChange={(e) => groupChoseFun(e, item)}
                checked={isChecked(item.id)}
              >
                {gettitle(item)}
              </Checkbox>
            </div> */}
          </div>
        )
      );
    });
    return groups;
  }
  return <div className="sl-auth-group">{getGroup()}</div>;
}
