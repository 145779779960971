import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import CountUp from "react-countup";
import SlIcon from "../sl-icon";

import "./style.less";

export default class DataBlockState extends Component {
  static propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    count: PropTypes.number,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  };

  renderItems = () => {
    const { data } = this.props;
    const nodes = [];
    if (data && data.length > 0) {
      data.forEach((bd) => {
        nodes.push(
          <div className="block-item">
            <div className="block-label">{bd.title}</div>
            <div className="block-data">{bd.count}</div>
          </div>
        );
      });
    }
    return nodes;
  };
  render() {
    const { iconBgColor, color, color2, icon, count, tip } = this.props;

    // const blockStyle = {
    //     border: `1px solid ${color}`,
    // };

    const blockStyle = {
      background: `linear-gradient(to left,${color},${color2})`,
    };

    const iconStyle = {
      background: iconBgColor,
    };

    return (
      <div className="data-block-state-root">
        <div className="data-block-warp">
          {this.renderItems()}
          <div className="block-icon">
            <SlIcon type={icon} style={{ width: 58, height: 58 }}></SlIcon>
          </div>
        </div>
      </div>
    );
  }
}
