import React from 'react';
import PropTypes from 'prop-types';
import {Icon,Form} from 'antd';
import classNames from 'classnames';
import {SlIcon} from '../'
import './index.less';

export default class QueryBar extends React.Component {
    static propTypes = {
        collapsed: PropTypes.bool,          // 展开/收起 状态
        onCollapsedChange: PropTypes.func,  // 展开/收起 状态改变
        handleSearch: PropTypes.func
    };

    static defaultProps = {
        onCollapsedChange: collapsed => collapsed,
    };

    state = {
        showCollapsed: false,
    };

    static getDerivedStateFromProps(nextProps) {
        const showCollapsed = 'collapsed' in nextProps;

        return {showCollapsed};
    }

    handleCollapsedChange = (e) => {
        e.preventDefault();
        const {onCollapsedChange, collapsed} = this.props;

        if (onCollapsedChange) {
            onCollapsedChange(!collapsed);
        }

        // 页面内容有改动，页面中有可能有撑满全屏的元素，需要调整
        // 切换时，滚动条会有闪动，需要调整body的overflow
        const oldOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            document.body.style.overflow = oldOverflow;
        });
    };
    render() {
        const {
            collapsed,
            className,
            onCollapsedChange,
            defaultRow,
            rowStyle,
            otherRow,
            ...others
        } = this.props;
        const {showCollapsed} = this.state;

        return (
            <div
                className={classNames(className, 'sx-query-bar', {'with-collapse': showCollapsed})}
                {...others}
            >
                  <Form onSubmit={this.props.handleSearch} >
                    {this.props.children}
                    <div className="defalut-row" style={rowStyle}>
                    {defaultRow}
                    {/* {
                    showCollapsed ? (
                          <SlIcon onClick={this.handleCollapsedChange} type={'icon_collapse'} style={{width:24,height:24,marginLeft:8}} transform={collapsed?'':180}/>
                    ) : null
                } */}
                    </div>
                </Form>
            </div>
        );
    }
}
