import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table,ConfigProvider,Icon } from "antd";
import { Pagination,SlEmpty,SlIcon } from "@/library/components";
import { getElementTop } from "@/library/utils";
import { connect } from "../../../models/index";
import "./style.less";
import dayjs from "dayjs";
const customizeRenderEmpty = () => (
  //这里面就是我们自己定义的空状态
  <SlEmpty content="暂无数据" style={{paddingTop:30}}/>
);

@connect((state) => {
  const { collapsed, collapsedWidth } = state.side;
  return {
    sideCollapsed: collapsed,
    sideCollapsedWidth: collapsedWidth,
  };
})
export default class TableComponent extends Component {
  static propTypes = {
    surplusSpace: PropTypes.bool, // 是否使用剩余空间，如果 true 表格将铺满全屏
    serialNumber: PropTypes.bool, // 是否显示序号
    serialText: PropTypes.string,
    otherHeight: PropTypes.number,
    offsetHeight: PropTypes.number,
    pagination: PropTypes.bool,
  };

  static defaultProps = {
    surplusSpace: true,
    pagination: true,
    pageSize: 10,
    pageNum: 1,
    serialText: "序号",
  };

  state = {
    tableBodyHeight: 450,
  };

  componentDidMount() {
    if (this.props.surplusSpace) {
      this.setTableBodyHeight();
      window.addEventListener("resize", this.setTableBodyHeight);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.surplusSpace) {
      const prevDataSource = prevProps.dataSource;
      const { dataSource } = this.props;

      if (dataSource?.length !== prevDataSource?.length) {
        this.setTableBodyHeight();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.surplusSpace) {
      window.removeEventListener("resize", this.setTableBodyHeight);
    }
  }
customizeRenderEmpty = () => (
    //这里面就是我们自己定义的空状态
    <SlEmpty content="暂无数据"/>
)
  setTableBodyHeight = () => {
    this.tableBody = this.wrapper.querySelector(".ant-table-body");
    this.tablePlaceholder = this.wrapper.querySelector(
      ".ant-table-placeholder"
    );
    this.tableHead = this.wrapper.querySelector(".ant-table-thead");

    const { pathname, search } = window.location;
    const currentPath = window.decodeURIComponent(`${pathname}${search}`);
    const activeTab = document.getElementById(currentPath);
    this.pagination = (activeTab ? activeTab : document).querySelector(
      ".pagination-wrap"
    );

    let tableBodyHeight;
    const { dataSource } = this.props;
    const windowHeight = document.documentElement.clientHeight;
    const windowWidth = document.documentElement.clientWidth;

    // 计算除了表格内容之外，其他高度
    if ("otherHeight" in this.props) {
      const { otherHeight } = this.props;
      tableBodyHeight = windowHeight - otherHeight;
    } else {
      const tableHeadHeight = this.tableHead.offsetHeight + 1;
      const paginationHeight = this.pagination
        ? this.pagination.offsetHeight + 8
        : 0;
      const bottomHeight = paginationHeight + 10 + 10;

      const tableOffsetTop =getElementTop(this.wrapper);
      const otherHeight = tableOffsetTop + tableHeadHeight + bottomHeight;
      
      tableBodyHeight = windowHeight - otherHeight+(windowWidth<1300?190:0);
      if ("offsetHeight" in this.props)
        tableBodyHeight = tableBodyHeight - this.props.offsetHeight;
    }
    tableBodyHeight=tableBodyHeight+100;
    if (dataSource?.length) {
      this.tableBody.style.height = `${tableBodyHeight}px`;
    } else {
      this.tableBody.style.height = "0px";
      this.tablePlaceholder.style.height = `${tableBodyHeight}px`;
    }

    this.setState({ tableBodyHeight });
  };
 

  render() {
    let {
      scroll = { x: 1000 },
      pagination,
      surplusSpace,
      serialNumber,
      ranking,
      serialText,
      // 分页属性

      size,
      showSizeChanger,
      showQuickJumper,
      showMessage,
      pageSize,
      pageNum,
      total,
      onPageNumChange,
      onPageSizeChange,

      rowSelection,
      columns,
      custPage,
      lastRow,
      followRows,
      sideCollapsed,
      sideCollapsedWidth,
      otherWidth = 0,
      fixed = false,
      rowHeight=40,
      contentStyle,
      ...others
    } = this.props;
    const { tableBodyHeight } = this.state;
    let tableScroll = surplusSpace ? { y: tableBodyHeight, ...scroll } : scroll;

    if (rowSelection === true) rowSelection = {};

    if (!rowSelection) rowSelection = void 0;

    if (serialNumber) {
      columns = [
        {
          align: fixed ? "left" : "center",
          title: ranking?"排名":serialText,
          width: 60,
          dataIndex: "__num",
          key: "__num",
          render: (value, record, index) =>{
            if(ranking ){
              return  index<3?  <SlIcon
              type={index===0?"icon_gold":(index===1?"icon_silver":"icon_copper")}
              style={{ width: 40, height: 40 }}
            />:<div style={{fontWeight:'bold',height: 40,lineHeight: 2,fontSize:16}}>{index + 1 + pageSize * (pageNum - 1)}</div>
            }
           return index + 1 + pageSize * (pageNum - 1);
          }
        },
        ...columns,
      ];
    }
    const tabwidth = sideCollapsed
      ? document.body.clientWidth - 130
      : document.body.clientWidth - (310);

    return (
      <div
        ref={(node) => (this.wrapper = node)}
        className="sl-content"
        style={{width: tabwidth - otherWidth, zIndex: 3, ...contentStyle }}
      >
         <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          scroll={tableScroll}
          pagination={false}
          rowSelection={rowSelection}
          {...others}
          columns={columns}
          rowClassName={(record, index) => {
            if (lastRow === index) {
              return "lastRow"; //上次操作行
            }else 
            if (record.hasOwnProperty('lastFollowTime') && dayjs(record.lastFollowTime).format("YYYY-MM-DD")===dayjs().format("YYYY-MM-DD")) {
              return "followRow"; //今日跟进行
            } else {
              if (index % 2 === 1) {
                return rowHeight===40?"slBgRow":"slBasicRow-40";//偶数行className
              } else {
                return rowHeight===40?"slBasicRow":"slBasicRow-40"; //奇数行className
              }
            }
          }}
          style={{ border: "1px solid #E4EAEC" }}
        />
        </ConfigProvider>
        {pagination ? (
          <Pagination
            size={size}
            showSizeChanger={showSizeChanger}
            showQuickJumper={showQuickJumper}
            showMessage={showMessage}
            pageSize={pageSize}
            pageNum={pageNum}
            total={total}
            onPageNumChange={onPageNumChange}
            onPageSizeChange={onPageSizeChange}
          />
        ) : (
          custPage
        )}
        {/* {custPage?{custPage}:null} */}
      </div>
    );
  }
}
