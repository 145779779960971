import React, { Component } from "react";
import { Form, Button, Icon, Skeleton, Drawer,List, Avatar, } from "antd";
import config from "@/commons/config-hoc";
import {
  ModalContent,
  SlMoreTab,
  FormElement,
  SlIcon,
  FormRow,
  SlEmpty,
  SlLogItem
} from "@/library/components";
import { getLoginUser, DOC } from "@/commons";
import { sortKey } from "@/library/utils";
import TweenOne from 'rc-tween-one';
import "./style.less";

@config({
  ajax: true,
  hideTabe: props =>props.crtMenu==="index",
  path:"/msg",
  router: true,
  connect(state) {
    const { depOrgs, depGroups, depUserList } = state.department;
    return {
      depOrgs,
      depGroups,
      depUserList: depUserList.slice(1, depUserList.length),
    };
  },
})
@Form.create()
export default class MsgList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // 页面加载loading
      initLoading: true,
      data:[],
      list:[],
      followRecords:[],
      total: 0, // 分页中条数
      pageNum: 1, // 分页当前页
      pageSize: 20, // 分页每页显示条数
    };
    const { form } = this.props;
    const formProps = {
      labelWidth: 100,
      form,
    };
  }
  componentDidMount() {
    this.props.onRef(this)
    //this.search();
  }

 
  fetchData = (goId) => {
    // if (this.state.loading) return;
    this.setState({ loading: true });
    this.props.ajax
      .get(`/customer/${73123}`)
      .then((res) => {
        var msgs=[]
       if(res.followRecords.length>0){
        msgs=res.followRecords;
        for (let index = 0; index < msgs.length; index++) {
            msgs[index]['type']=index%2===0?1:0; 
        }
       }
       debugger;
       this.setState({
        data: res,
        followRecords: msgs?.length
          ? sortKey(msgs, "created")
          : [],
      });
      this.props.form.resetFields();
        
      })
      .finally(() => this.setState({ loading: false,initLoading:false }));
  };
  search( page){
    
    const {pageNum,pageSize,data}=this.state;
    const params = {
        current:page!=null?page: pageNum,
        size: pageSize,
        userId:getLoginUser()?.id
      };
      this.setState({ loading: true });
      this.props.ajax
        .list("/fenxiao/customer/dynamic/list", params)
        .then((res) => {
          if(res.code===200){
            const dataSource = res?.data?.records || [];
            const total = res?.data?.total || 0;
  
            this.setState({data:page===1? dataSource:  data.concat(dataSource), total });
          }
         
        })
        .finally(() => this.setState({ loading: false,initLoading:false  }));

  }
  onLoadMore(){
    const { initLoading, loading, total,data,pageNum} = this.state;
      if( initLoading || loading ){
          return
      }
      if(data.length<total){
        this.search(pageNum+1);
        this.setState({
            pageNum:pageNum+1
        })
      }
  }
  itemClick(){
    
  }
  
  render() {
    const {
      visible,
      onOk,
      onClose,
      history
    } = this.props;
    const { initLoading, loading,data,total,pageSize} = this.state;
    const loadMore =
      !initLoading && !loading && total>pageSize ? (
      
        <div
          style={{
           
            marginTop: 12,
        
          }}
        >
           <SlIcon type={"icon_more"} style={{ width: 40, height: 40 }} onClick={this.onLoadMore()} />
        </div>
      ) : null;
    return (
      <Drawer
        ref={(node) => (this.wrapper = node)}
        title={
            <div styleName="msg-drawer-header">
      <TweenOne
      animation={{ 
        x: -120, 
        scale: 0.5, 
        rotate: 120, 
        yoyo: true, // demo 演示需要
        repeat: -1, // demo 演示需要
        duration: 1500
      }}
      paused={false}
      style={{ transform: 'translateX(120px)' }}
      
    > 
     <img src={require("../../assets/icon/msg_noti.png")} alt="" style={{width:50,height:50}}></img>
    </TweenOne>
    
     消息通知
     <TweenOne
      animation={{ 
        x: "120", 
        scale: 0.5, 
        rotate:- 120, 
        yoyo: true, // demo 演示需要
        repeat: -1, // demo 演示需要
        duration: 1500
      }}
      paused={false}
      style={{ transform: 'translateX(-120px)' }}
      
    > 
     <img src={require("../../assets/icon/msg_noti.png")} alt="" styleName="mirrorRotateLevel" style={{width:50,height:50}}></img>
    </TweenOne>
            </div>
        }
        headerStyle={{ pandding: "0px 0px", position: "relative" ,backgroundColor: "#21212C"}}
        placement={"right"}
        closable={false}
        keyboard={true}
        width={"25%"}
        style={{ textAlign: "center" }}
        bodyStyle={{ padding: "12px 0px" }}
        onOk={()=>{}}
        onClose={() => onClose()}
        visible={visible}
        mask={true}
        key={"msg88"}
      >
          <div styleName="msg-drawer-content">
          <List
        className="demo-loadmore-list"
        loading={loading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={data}
        renderItem={item => (
          <List.Item

          >
               <SlLogItem data={item} onClick={()=>{
                 if(item.type===3){
                history.push("/contract_manager", { id: item.customerId });
                 }else{
                 history.push("/customer1-1", {
                    id: 73864,
                  });
                 }
                 onClose();
               }}></SlLogItem>
            {/* <Skeleton avatar title={false} loading={item.loading} active> */}
             
            {/* </Skeleton> */}
          </List.Item>
        )}
      />
          </div>
        
      </Drawer>
    );
  }
}
