import 'braft-editor/dist/index.css'
import React from 'react'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import { Upload,Icon} from 'antd'
import SlButton from '../sl-button'

export default class SlColorInput extends React.Component {

  state = {
    editorState: BraftEditor.createEditorState(null)
  }
  componentDidMount(){
    this.props.onRef(this)
  }

  handleChange = (editorState) => {
    this.setState({ editorState })
    const {onChange}=this.props;
    onChange(editorState.toHTML());
  }
  handleSave=()=>{
    const {onSave}=this.props;
    onSave();
  }
  clearBody=()=>{
    this.setState({
        editorState: BraftEditor.createEditorState(null)
    })
  }
  uploadHandler = (param) => {

    if (!param.file) {
      return false
    }
  
    this.setState({
      editorState: ContentUtils.insertMedias(this.state.editorState, [{
        type: 'IMAGE',
        url: URL.createObjectURL
      }])
    })

  }

  render () {
    const {onSave}=this.props 
    const controls = ['font-size', 'line-height', 'letter-spacing', 'separator','bold', 'italic','underline', 'text-color', 'separator','text-indent', 'text-align', 'separator',
    'headings', 'list-ul', 'list-ol', 'font-family','blockquote', 'link', 'separator','emoji','clear']
    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload
            accept="image/*"
            showUploadList={false}
            customRequest={this.uploadHandler}
          >
            {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
            <button type="button" className="control-item button upload-button" data-title="插入图片">
              <Icon type="picture" theme="filled" />
            </button>
          </Upload>
        )
      }
    ]

    return (
      <div>
        <div className="editor-wrapper">
          <BraftEditor
            value={this.state.editorState}
            placeholder={"跟进记录"}
            onChange={this.handleChange}
            onSave={this.handleSave}
            controls={controls}
            extendControls={extendControls}
            contentStyle={{height:260}}
          />
        </div>
      </div>
    )

  }

}