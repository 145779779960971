export default {
    initialState: {
        title: void 0,
        reportInfos:[],
        reportInfo:null
    },
    setReportInfos:reportInfos => ({reportInfos}),
    setReportInfo:reportInfo => ({reportInfo}),
    appendReportInfo: (infos, state) => {
        let {reportInfos = []} = state;
        reportInfos = reportInfos.concat(infos);
        return {reportInfos};
    },
    //获取详情
  getReportInfo: {
    payload: ({ params} = {},state) =>{
        return Promise.resolve(params)
    },
    reducer: {
      resolve: (state, { payload: res }) => {
        const {reportInfos}=state;
        const reportInfo=reportInfos.find((report)=>report.id===res.id)
        console.log(reportInfo,reportInfos,'reportInfo')
        return { reportInfo: reportInfo };
      },
    },
  },
}