import Mock from 'mockjs';

const random = Mock.Random;
const LoginUsers = [
    {
        id: '5754195b570b367345584998',
        name: '超级管理员',
        loginName: 'admin',
        gender: 'male',
        org_key: '1464444570801',
        roleId: '5753f4706df8f6094bf3fc54',
        pass: '111111',
        salt: '03525b62-15aa-4ffe-a1ee-0385498e01f9',
        update_at: '2016-08-23T13:44:18.819Z',
        create_at: '2016-06-05T12:21:47.666Z',
        is_deleted: false,
        is_locked: false,
        is_first_login: false,
        position: 'CEO',
        mobile: '88888888888',
        email: 'admin@163.com',
        permissions: ['users-add', 'users-delete', 'users-update', 'users-search', 'users-reset-pass', 'users-toggle-lock', 'organization-add', 'organization-delete', 'organization-update', 'organization-search', 'role-add', 'role-update', 'role-delete', 'role-search', 'system', 'system-004002', 'system-004001', 'system-002'],
    },
    {
        id: '5754195b570b367345584998',
        name: '超级管理员',
        loginName: 'admin2',
        gender: 'male',
        org_key: '1464444570801',
        roleId: '5753f4706df8f6094bf3fc54',
        pass: '111111',
        salt: '03525b62-15aa-4ffe-a1ee-0385498e01f9',
        update_at: '2016-08-23T13:44:18.819Z',
        create_at: '2016-06-05T12:21:47.666Z',
        is_deleted: false,
        is_locked: false,
        is_first_login: false,
        position: 'CEO',
        mobile: '88888888888',
        email: 'admin@163.com',
        permissions: ['users-add', 'users-delete', 'users-update', 'users-search', 'users-reset-pass', 'users-toggle-lock', 'organization-add', 'organization-delete', 'organization-update', 'organization-search', 'role-add', 'role-update', 'role-delete', 'role-search', 'system', 'system-004002', 'system-004001', 'system-002'],
    },
];

export function getChannelByPageSize(pageSize) {
    const channels = [];
    for (let i = 0; i < pageSize; i++) {
        channels.push(Mock.mock({
            id: random.guid(),
            code:random.character('upper'),
            'name|1': ['xxx有限公司', 'xxx科技公司','xxx媒体', 'xxx公司'],
            customerNum: random.integer( 12, 10000 ),
            totalMoney: random.float( ),
            'totalMoney|1': ['2200万', '5030万','1090万', '2830万'],
            date:random.date(),
            mobile: '18611438888',
           
        }));
    }
    return channels;
}

export {LoginUsers};
