import React from 'react';
import './style.less';
import imgarr from '@/library/utils/imgarr'
import classNames from 'classnames';
export default function (props) {
    const {style,className,onClick,content}=props
    return (
        <div className="sl-empty-warp">
            <img className={classNames(className)}  style={style} src={imgarr["empty_img"]} onClick={onClick} alt=''/>
            <div className="content">{content}</div>
       </div>
    );
}
