
import {getCustomersByPageSize} from './mockdata/customer'
export default {
    
    'get /mock/customers': (config) => {
        const {
            pageSize,
            pageNum,
        } = config.params;


        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([200, {
                    pageNum,
                    pageSize,
                    total: 888,
                    list: getCustomersByPageSize(pageSize),
                }]);
            }, 1000);
        });
    },
    'get re:/mock/users/.+': {id: 1, name: '熊大', age: 22, job: '1', position: '1'},
    'post /mock/users': true,
    'put /mock/users': true,
    'delete /mock/users': true,
    'delete re:/mock/users/.+': true,
}
