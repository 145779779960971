import React from 'react';
import './style.less';
import imgarr from '@/library/utils/imgarr'
import classNames from 'classnames';
export default function (props) {
    const {type,style,className,onClick,transform}=props
    const iconclass=type==='square'?'icon-deafult':'sl-img'
    return (
       <img className={classNames(className,iconclass,{transform_180:transform===180})}  style={style} src={imgarr[type]} onClick={onClick} alt=''/>
    );
}
