import { sxAjax } from "@/commons/ajax";
import { getLoginUser } from "@/commons";
export default {
    initialState: {
        title: void 0,
        newCount:0,
        msgs:[],
        isNewMsg:false,
        phoneLoading:false,
        customerTop:{
            toplimit:0,//当前主账号客户上限
            currentNum:0,//当前主账号客户数量
            newCustomerToplimit:0,//新增客户上限
            newCustomerCurrentNum:0,//新增客户数
            contractCurrentNum:0,//当前合同客户客户数量
            systemDistributionCustomerCurrentNum:0,//当前系统划分客户数量客户数量
            systemDistributionCustomerParameter:0,//系统划分客户数量参数配置
            dailyFollowCustomerNum:0 //当日已跟进
          },
          unFollow:{
              count:0,
              ids:[]
          },
          reminds:[],
          showReminds:false,
          crtRemind:0
    },
    syncStorage: {
        newCount:true,
        msgs:true,
        isNewMsg:true,
    },
    
    setNewCount:newCount => ({newCount}),
    setMsgs:msgs => ({msgs}),
    setIsNewMsg:isNewMsg => ({isNewMsg}),
    setCustomerTop:customerTop=>({customerTop}),
    setUnFollow:unFollow => ({unFollow}),
    setPhoneLoading:phoneLoading=>({phoneLoading}),
    setCrtRemind:crtRemind=>({crtRemind}),
    showReminds: () => ({ showReminds: true }),
    hideReminds: () => ({ showReminds: false }),
    goNextRemind:(index)=>({crtRemind:index}),
      //划转客户
    moveCustomer: {
        payload: ({ params } = {}) => sxAjax.post("/move/customer",params,{successTip: "划转成功"} ),
        reducer: {
        resolve: (state, { payload: res }) => {
            upCount( {id:getLoginUser()?.id}).then((res)=>{
                postCount(res);
                state.customerTop=res;
                upFollowCount( {id:getLoginUser()?.id}).then((ures)=>{
                    state.unFoloowCount=ures
                })
            })
           
            return res;
        },
        },
    },
       //跟新客户统计
    upCustomerTop: {
        payload: ({  params, options } = {}) => upCount( params, options),
        reducer: {
        resolve: (state, { payload: res }) => {
            postCount(res);
            return res ;
        },
        },
    },
    upUnflollowCount:{
        payload: ({  params, options } = {}) => upFollowCount( params, options),
        reducer: {
        resolve: (state, { payload: res }) => {
            state.unFollow.count=res?.count;
            var ids="";
            if(res.ids.length>0){
                    res.ids.forEach(id => {
                        ids=ids+id+",";
                    });
            }
            state.unFollow.ids=ids;
            return res;
        },
        },
    },
         //获取还款合同
         getReminds: {
            payload: ({ params } = {}) => sxAjax.get("/contract/remind/"+params.day+"/"+params.userId,params ),
            reducer: {
            resolve: (state, { payload: res }) => {
                state.reminds=res;
                if(res && res.length>0){
                    state.showReminds=true
                }
                return res;
            },
            },
        },
}
function upCount( params, options ) {
   return sxAjax.get("/customer/disc/count/"+params['id'], params, options);
}
function upFollowCount( params, options ) {
    return sxAjax.get("/customer/no_follow_count", params, options);
 }
function postCount(data){
    window.parent.postMessage({
        data :data,type:"upCount"
      },'*');
}