import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { swap } from 'react-magic';
import logo from './logo.png';
import './style.less';
const styles = StyleSheet.create({
    magic: {
        animationName: swap,
        animationDuration: '1s'
    }
});
export default class Logo extends Component {
    static propTypes = {
        min: PropTypes.bool,
    };
    static defaultProps = {
        logo: logo,
        title: 'React Web',
        min: false,
    };

    render() {
        const {min, title, ...others} = this.props;
        return (
            <div styleName="logo" className={css(styles.magic)}>
                <img src={logo} alt="logo"/>
                <h1 {...others} className={min ? 'title-hide' : ''}>{title}</h1>
            </div>
        );
    }
}
