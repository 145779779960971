import React, {Component} from 'react';
import {Menu, Dropdown, Icon,Avatar,Modal} from 'antd';
import {Link} from 'react-router-dom';
import {loginOut, getLoginUser,hasPermission} from '@/commons';
import ModifyPassword from './ModifyPassword';
import config from '@/commons/config-hoc';
import './style.less';
import down from '../../assets/icon/down.png'
import appPng from '../../assets/imgs/down.png'

const Item = Menu.Item;

@config({ajax: true})
export default class HeaderUser extends Component {
    static defaultProps = {
        theme: 'default',
    };

    state = {
        passwordVisible: false,
        visible: false,
    };

    handleMenuClick = ({key}) => {
        if (key === 'logout') {
            //退出登录
            this.props.ajax.get(`/logout/${getLoginUser().id}`)
            .then(() => {
              this.props.action.system.closeAllTabs();
                loginOut()
            })
            .finally();
        }

        if (key === 'modifyPassword') {
            this.setState({passwordVisible: true});
        }
        if(key==="down"){
            this.showModal();
        }
    };
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };

    render() {
        const user = getLoginUser() || {};
        const name = user.name;

        const {className, theme} = this.props;
        const menu = (
            <Menu styleName="menu" theme={theme} selectedKeys={[]} onClick={this.handleMenuClick}>
                {/* <Item key="modifyPassword"><Icon type="edit"/>修改密码</Item> */}
                <Item><Link to="/person_info"><Icon type="user"/>个人中心</Link></Item>
                {/* <Item><Link to="/settings"><Icon type="setting"/>设置</Link></Item>
                <Item><Link to="/menu-permission"><Icon type="lock"/>菜单</Link></Item> */}
                {/* <Item><Link to="/menu-permission"><Icon type="lock"/>菜单</Link></Item> */}
                {hasPermission('MENU_MANNGER') ? (
                    
                    <Item><Link to="/menu-permission"><Icon type="lock"/>菜单</Link></Item>
                 ) : null} 
                <Menu.Divider/>
                <Item key="down"><Icon type="download"/>下载助手</Item>
                <Item key="logout"><Icon type="logout"/>退出登录</Item>
            </Menu>
        );
        return (
            <div styleName="user-menu" ref={node => this.userMenu = node}>
                <Dropdown overlay={menu} getPopupContainer={() => (this.userMenu || document.body)}>
                    <span styleName="account" className={className}>
                        <Avatar src={user.avatar ||  require('../../assets/icon/men.png')} style={{width:36,height:36,marginRight:9}}/>
                        {/* <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf',width:40,height:40,marginRight:9 }}>U</Avatar> */}
                        <span style={{fontSize: 14}}>{name}</span>
                        <img src={down} width='14px' style={{marginLeft:9}} alt=''></img>
                    </span>
                </Dropdown>

                <ModifyPassword
                    visible={this.state.passwordVisible}
                    onOk={() => this.setState({passwordVisible: false})}
                    onCancel={() => this.setState({passwordVisible: false})}
                />
                <Modal
                width={'420px'}
                style={{padding:'0'}}
                bodyStyle={{padding:'0',textAlign:'center'}}
          title={
            <div
              style={{
                width: '100%',
                padding:'0',
                cursor: 'move',
                textAlign:'center'
              }}
              // end
            >
             扫码下载锐丞助手
            </div>
          }
          footer={true}
          visible={this.state.visible}
          centered={true}
          onCancel={this.handleCancel}
        //   modalRender={modal => <Draggable disabled={this.state.disabled}>{modal}</Draggable>}
        >
          <img src={appPng} width='420px' height='400px' alt=''></img>
        </Modal>
            </div>
        );
    }
}
