import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import classNames from 'classnames';
import './style.less';

export default class SlButton extends React.Component {
    static propTypes = {
        type: PropTypes.any,          // 按钮的类型
        title: PropTypes.any,          // 按钮的title
        onClick: PropTypes.func,  // 点击事件
    };
    render() {
        const {
            type,
            title,
            icon,
            onClick,
            className,
            disabled,
            ...others
        } = this.props;
        const butclass=type?'btn-'+type:'btn'
        return (
            <Button
                className={classNames(className,butclass,{'disabled':disabled})}
                onClick={disabled?{}:onClick}
                type={type==='success'?'primary':type}
                {...others}
            >
                {icon}
             {title}
             
            </Button>
        );
    }
}
