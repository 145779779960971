import React from 'react';
import PropTypes from 'prop-types';
import {Icon,Form,Button,List} from 'antd';
import classNames from 'classnames';
import './style.less';

export default class SlMoreTab extends React.Component {
    static propTypes = {
        type: PropTypes.any,          // 按钮的类型
        title: PropTypes.any,          // 按钮的title
        changeTab: PropTypes.func,  // 点击事件,
        crtTab: PropTypes.any,//当前crttab
    };
    changeTab(item){
        const {changeTab}=this.props
        if(changeTab){
            changeTab(item.value)
        }
    }
    renderItem(data,crtTab){
        var items=[]
        data.map((item,index)=>{
            items.push(
                <div key={index} onClick={()=>{this.changeTab(item)}} className={classNames('sl-more-tab-item',{'active':item.value===crtTab})}>
                <span>{item.title}</span>
                {item.value===crtTab?<div className='line'></div>:null}
            </div>
            )
        })
        return items
    }
    render() {
        const {
            className,
            data,
            crtTab,
        } = this.props;
        return (
           <div className="sl-more-tab-warp">
               {this.renderItem(data,crtTab)}
           </div>
        );
    }
}
