import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import CountUp from "react-countup";
import "./style.less";
import SlIcon from "../sl-icon";
export default class PhoneBlock extends Component {
  handelDoFun=(data)=>{
      console.log(data,'hhhhhhh')
    const {dofun}=this.props
    if(data?.id){
        if (dofun) dofun(data);
    }else{
        if (dofun) dofun();
    }
  }

  render() {
    const {
      iconBgColor,
      color,
      color2,
      icon,
      count,
      tip,
      data,
    } = this.props;

    // const blockStyle = {
    //     border: `1px solid ${color}`,
    // };

    const blockStyle = {
      background: `linear-gradient(to left,${color},${color2})`,
    };

    const iconStyle = {
      background: iconBgColor,
    };

    return (
      <div className="phone-block-root" style={blockStyle}>
          {data?.id?
          
          <div className="block-row">
          <div className="block-icon">
            <SlIcon
              type={data?.type === 1 ? "icon_selected" : "icon_un_selected"}
              style={{ width: 32, height: 32 }}
            ></SlIcon>
          </div>
          <div className="block-data">{data?.phone}</div>
          <div className="block-slider"></div>
          <div className="block-button" onClick={()=>this.handelDoFun(data)}>
            删除
          </div>
        </div>:<div className="block-row" onClick={this.handelDoFun}>
          <Icon type="plus"  style={{color:"rgba(221, 221, 221, 1)",fontSize:30}}></Icon>
        </div>
        }
      </div>
    );
  }
}
