export default {
  icon_collect: require("../../assets/icon/conllect.png"),
  icon_down: require("../../assets/icon/down.png"),
  icon_org: require("../../assets/icon/org.png"),
  icon_auth: require("../../assets/icon/auth.png"),
  icon_kpi: require("../../assets/icon/kpi.png"),
  icon_op: require("../../assets/icon/op.png"),
  icon_customer: require("../../assets/icon/customer.png"),
  icon_org_no: require("../../assets/icon/org_no.png"),
  icon_auth_no: require("../../assets/icon/auth_no.png"),
  icon_kpi_no: require("../../assets/icon/kpi_no.png"),
  icon_op_no: require("../../assets/icon/op_no.png"),
  icon_setting_no: require("../../assets/icon/setting_no.png"),
  icon_customer_no: require("../../assets/icon/customer_no.png"),
  icon_collapse: require("../../assets/icon/collaps.png"),
  icon_setting: require("../../assets/icon/setting.png"),
  icon_contract: require("../../assets/icon/contract.png"),
  icon_contract_no: require("../../assets/icon/contract_no.png"),
  icon_full: require("../../assets/icon/full.png"),
  icon_no_full: require("../../assets/icon/no_full.png"),
  icon_gj: require("../../assets/icon/gj.png"),
  icon_lc:require("../../assets/icon/lc.png"),
  icon_save: require("../../assets/icon/save.png"),
  icon_save_close:require("../../assets/icon/save_close.png"),
  icon_next: require("../../assets/icon/next.png"),
  icon_last:require("../../assets/icon/last.png"),
  icon_no_last:require("../../assets/icon/no-last.png"),
  icon_close:require("../../assets/icon/close.png"),
  icon_black_close:require("../../assets/icon/closeb.png"),
  icon_play:require("../../assets/icon/play.png"),
  icon_stop:require("../../assets/icon/stop.png"),
  icon_app: require("../../assets/icon/app.png"),
  icon_app_no: require("../../assets/icon/app_no.png"),

  icon_gold:require("../../assets/icon/gold.png"),
  icon_copper:require("../../assets/icon/copper.png"),
  icon_silver:require("../../assets/icon/silver.png"),
  icon_add_count:require("../../assets/icon/add_count.png"),
  icon_sign_count:require("../../assets/icon/sign_count.png"),
  icon_submit_count:require("../../assets/icon/submit_count.png"),

  icon_visit_count:require("../../assets/icon/visit_count.png"),
  icon_locan_count:require("../../assets/icon/locan_count.png"),
  icon_default:require("../../assets/icon/default.png"),
  icon_pass:require("../../assets/icon/pass.png"),
  icon_selected:require("../../assets/icon/selected.png"),
  icon_un_selected:require("../../assets/icon/un_selected.png"),
  icon_email:require("../../assets/icon/email.png"),

  icon_msg_more:require("../../assets/icon/msg_more.png"),
  icon_msg_new:require("../../assets/icon/msg_new.png"),
  icon_more:require("../../assets/icon/more.png"),


  team_img: require("../../assets/imgs/team.png"),
  zon_img: require("../../assets/imgs/zon.png"),
  msg_center_img: require("../../assets/imgs/zon.png"),

  empty_img: require("../../assets/imgs/empty.png"),
  icon_wx: require("../../assets/icon/wx_add.png"),
  icon_log:require("../../assets/icon/icon_log.png"),
  icon_no:require("../../assets/icon/icon_no.png"),
  icon_log_save:require("../../assets/icon/icon_save.png"),
  icon_moshu:require("../../assets/icon/icon_moshu.png"),
  icon_add:require("../../assets/icon/icon_add.png"),
  icon_to_fast:require("../../assets/icon/icon_to_fast.png"),
  icon_to_input:require("../../assets/icon/icon_to_input.png"),
  icon_turn:require("../../assets/icon/icon_turn.png"),
};
