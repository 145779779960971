import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as storage from "@/library/utils/storage";
import * as sxRedux from "@/library/redux";
import { createBrowserHistory } from "history"; // 如果是history路由
import App from "./App";
import handleSuccess from "./commons/handle-success";
import handleError from "./commons/handle-error";
import { configureStore } from "./models";
import * as serviceWorker from "./serviceWorker";
import { getLoginUser } from "./commons";
import ROLEEUM from "./commons/roleEum.js"
import md5 from "js-md5";
import dayjs from "dayjs";
import { message,notification } from "antd";
import Notify from "@wcjiang/notify";
import "./index.css";

import "./polyfill";
import "core-js";
import "mutation-observer";
// import  "react-app-polyfill/ie9";
// import  "react-app-polyfill";
import "babel-polyfill";
import 'dayjs/locale/zh-cn'
const history = createBrowserHistory();
message.config({
  top: 100,
  duration: 2,
});
notification.config({
  placement: 'topRight',
  top: 50,
  duration: null,
});
React.Component.prototype.ROLEEUM=ROLEEUM;
React.Component.prototype.$md5 = md5;
React.Component.prototype.$message= message;
React.Component.prototype.$notification= notification;
dayjs.locale('zh-cn');
React.Component.prototype.$dayjs = dayjs;
React.Component.prototype.$router = history;
// dev 模式开启mock
if (process.env.NODE_ENV === "development" || process.env.MOCK === "true") {
  require("./mock/index");
  console.log("current mode is development, mock is enabled");
}
const notify = new Notify({
  message: "有消息了。", // 标题
  effect: "flash", // flash | scroll 闪烁还是滚动
  openurl: "http://8.136.213.14:10002/customer", // 点击弹窗打开连接地址
  onclick: () => {
    // 点击弹出的窗之行事件
    console.log("---");
  },
  // 可选播放声音
  audio: {
    // 可以使用数组传多种格式的声音文件
    file: "",
    // 下面也是可以的哦
    // file: 'msg.mp4'
  },
  // 标题闪烁，或者滚动速度
  interval: 1000,
  // 可选，默认绿底白字的  Favicon
  updateFavicon: {
    // favicon 字体颜色
    textColor: "#333333",
    // 背景颜色，设置背景颜色透明，将值设置为“transparent”
    backgroundColor: "rgba(255,255,255,0.2)",
  },
  // 可选chrome浏览器通知，默认不填写就是下面的内容
  notification: {
    title: "通知！", // 设置标题
    icon: "../public/msg.ico", // 设置图标 icon 默认为 Favicon
    body: "您来了一条新消息", // 设置消息内容
    renotify: true,
    tag: "erro",
  },
});
React.Component.prototype.$notify = notify;
const currentUser = getLoginUser() || {};

// 存储初始化 区分不同用户存储的数据
storage.init({
  keyPrefix: currentUser.id,
});

sxRedux.init({ storage, handleError, handleSuccess });

// models store
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
