import React, { Component } from "react";
import PropTypes from "prop-types";
import { BackTop, Spin,Modal,Badge,Icon } from "antd";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import PageHead from "../page-head";
import Header from "../header";
import Side from "../side";
import PageTabs from "../page-tabs";
import { connect } from "@/models/index";
import Breadcrumb from "../breadcrumb";
import { getLoginUser, getSelectedMenuByPath, loginOutNoLogin } from "@/commons";
import { PAGE_FRAME_LAYOUT } from "@/models/settings";
import { SlButton,SlIcon } from "@/library/components";
import SockJsClient from "react-stomp";
import msgIco from "../../msg.ico";
import { formatWang, sortKey,getNl } from "@/library/utils";
import {sxAjax} from '@/commons/ajax';
import { StyleSheet, css } from 'aphrodite';
import { perspectiveUpReturn } from 'react-magic';
import "./style.less";
import Notify from "@wcjiang/notify";
const wa=require("./wa.m4a");
const XHS=[
  "npwz",
  "sfcy",
  "hxgy",
  "dqcx",
  "phqy",
  "lfxz",
  "llwy",
  "styy",
  "qyfh",
  "mlsh",
  "sdcx",
]
// const styles = StyleSheet.create({
//   magic: {
//       animationName: perspectiveUpReturn,
//       animationDuration: '3s'
//   }
// });
const randomInt = (min, max) => Math.floor(Math.random() * (max - min) + min);
window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
var context = new window.AudioContext();
var source = null;
var audioBuffer = null;
//最好使用本地音频文件，无跨域问题
function playSound() {
   
   
	source = context.createBufferSource();//获取音频数据
	source.buffer = audioBuffer;
	source.connect(context.destination);
	source.start(); //立即播放
}
function initSound(arrayBuffer) {
   
   
	context.decodeAudioData(arrayBuffer, function (buffer) {
   
    //解码成功时的回调函数
		audioBuffer = buffer;
		playSound();
	}, function (e) {
   
    //解码出错时的回调函数
		console.log('Error decoding file', e);
	});
}
function loadAudioFile(url) {
   
   
	var xhr = new XMLHttpRequest(); //通过XHR下载音频文件
	xhr.open('GET', url, true);
	xhr.responseType = 'arraybuffer';
	xhr.onload = function (e) {
   console.log(this.response)
    //下载完成
		initSound(this.response);
	};
	xhr.send();
}
@withRouter
@connect((state) => {
  const { selectedMenu, menus } = state.menu;
  const { title, breadcrumbs, showHead, isReturn,hideTabe } = state.page;
  const {
    show: showSide,
    width,
    collapsed,
    collapsedWidth,
    dragging,
  } = state.side;
  const { loading, loadingTip,showCard,lastReport } = state.system;
  const {
    pageFrameLayout,
    pageHeadFixed,
    pageHeadShow,
    tabsShow,
  } = state.settings;
  const { newCount, msgs,reminds, showReminds, crtRemind, phoneLoading } = state.customer;
  return {
    menus,
    selectedMenu,
    showPageHead: showHead,
    title,
    breadcrumbs,

    showSide,
    sideWidth: width,
    sideCollapsed: collapsed,
    sideCollapsedWidth: collapsedWidth,
    globalLoading: loading,
    globalLoadingTip: loadingTip,
    sideDragging: dragging,
    layout: pageFrameLayout,
    pageHeadFixed,
    pageHeadShow, // 设置中统一控制的头部是否显示
    tabsShow,
    isReturn,
    newCount,
    msgs,
    hideTabe,
    showCard:showCard,
    lastReport:lastReport,
    reminds,
    showReminds,
    crtRemind,
    phoneLoading,
  };
})
export default class FrameTopSideMenu extends Component {
  constructor(...props) {
    super(...props);
    const {
      action: { menu, side, system },
    } = this.props;
    // 从Storage中获取出需要同步到redux的数据
    this.props.action.getStateFromStorage();

    const loginUser = getLoginUser();
    const userId = loginUser?.id;
    const roleId = loginUser?.roleId;
    const permissions = loginUser?.permissions;
    // 获取系统菜单 和 随菜单携带过来的权限
    this.state.loading = true;
    this.state.isReport= false;
    menu.getMenus({
      params: { userId, permissions },
      onResolve: (res) => {
        const menus = res || [];
        const paths = [];

        menus.forEach(({ type, path, code }) => {
          if (type === "2" && code) permissions.push(code);

          if (path) paths.push(path);
        });

        // 设置当前登录的用户到model中
        system.setLoginUser(loginUser);
      
          //读取客户量
        this.props.action.customer.upCustomerTop({
            params: { id:getLoginUser()?.id},
        });
        this.props.action.customer.upUnflollowCount({
          params: { id:getLoginUser()?.id},
      });
        
        // 保存用户权限到model中
        system.setPermissions(permissions);

        // 保存当前用户可用path到model中
        system.setUserPaths(paths);
      },
      onComplete: () => {
        this.setState({ loading: false });
      },
    });

    setTimeout(() => {
      // 等待getStateFromStorage获取配置之后再设置
      menu.getMenuStatus();
      side.show();
      this.setTitleAndBreadcrumbs();
    });

    this.props.history.listen(() => {
      // 加上timeout之后，tab页切换之后，对应页面就不render了，不知道为什么！
      setTimeout(() => {
        menu.getMenuStatus();
        side.show();
        this.setTitleAndBreadcrumbs();
      });
    });
  }
  
  componentDidMount(){
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener("message",e => this.handleMessage(e)) // 子接收方式二参数
    window.addEventListener('unload', e => this.unloadHandler(e))
    if(this.props.lastReport===null){
      this.props.action.system.getLastReport({
                params: { id:getLoginUser()?.id },
              });
    }
    setTimeout(() => {
     this.setState({
       isReport:true
     })
    },500);
  }
  componentWillUnmount(){
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  }
  handleMessage (event) { // 子接收父参数
    var data = event.data;
    switch (data.cmd) {
      case 'closeAdmin':
        // 处理业务逻辑
        if(getLoginUser()){
          // this.props.ajax.get(`/logout/${getLoginUser().id}`)
          //退出登录
          this.doLoginOut();
          //  loginOutNoLogin()//尝试清缓存
        }
        break
      default:
        break
    }
  }
  beforeunloadHandler(){
    this._beforeUnload_time=new Date().getTime();
  }
  unloadHandler (e){
    this._gap_time=new Date().getTime()-this._beforeUnload_time;
    //判断是窗口关闭还是刷新
    if(this._gap_time<=5){
      //如果是登录状态，关闭窗口前，移除用户
      if(getLoginUser()){
        // this.props.ajax.get(`/logout/${getLoginUser().id}`)
        this.props.action.system.closeAllTabs();
        //退出登录
        this.doLoginOut();
        
        //  loginOutNoLogin()//尝试清缓存
      }
    }
  }

   doLoginOut=async()=>{
    this.props.action.system.closeAllTabs();
    const res = await sxAjax.get(`/logout/${getLoginUser().id}`);
    loginOutNoLogin()
    if(res){
      await loginOutNoLogin()
    }
  }

  static propTypes = {
    layout: PropTypes.string,
  };

  static defaultProps = {
    layout: PAGE_FRAME_LAYOUT.SIDE_MENU, // top-menu side-menu
    pageHeadFixed: true, // 页面头部是否固定
  };
  state = {};

  setTitleAndBreadcrumbs() {
    const {
      action: { page },
      pageHeadShow,
      menus,
      title: prevTitle,
      breadcrumbs: prevBreadcrumbs,
    } = this.props;

    const selectedMenu = getSelectedMenuByPath(window.location.pathname, menus);
    let breadcrumbs = [];
    let title = "";
    if (selectedMenu) {
      title = {
        text: selectedMenu.text,
      };
      if (selectedMenu.parentNodes) {
        breadcrumbs = selectedMenu.parentNodes.map((item) => {
          return {
            key: item.key,
            icon: item.icon,
            text: item.text,
            path: item.path,
          };
        });
      }

      if (selectedMenu.path !== "/") {
        breadcrumbs.unshift({
          key: "index",
          icon: "home",
          text: "首页",
          path: "/",
        });
      }

      breadcrumbs.push({
        key: selectedMenu.key,
        icon: selectedMenu.icon,
        text: selectedMenu.text,
      });
    }

    // 从菜单中没有获取到，有肯能是当前页面设置了，但是没有菜单对应
    if (!breadcrumbs.length && prevBreadcrumbs && prevBreadcrumbs.length) {
      page.setBreadcrumbs(prevBreadcrumbs);
    } else {
      page.setBreadcrumbs(breadcrumbs);
    }

    // 从菜单中没有获取到，有肯能是当前页面设置了，但是没有菜单对应
    if (!title && prevTitle) {
      page.setTitle(prevTitle);
    } else {
      page.setTitle(title);
    }

    pageHeadShow ? page.showHead() : page.hideHead();
  }
  handleGetMsg = (msg) => {
    // var msgObj = msg?JSON.parse(msg):{};
    console.log(msg);
    const { msgs } = this.props;
    
    // if(msg.type===1){
    //     //读取客户量
    //     this.props.action.customer.upCustomerTop({
    //       params: { id:getLoginUser()?.id},
    //     });
    //     this.props.action.customer.upUnflollowCount({
    //       params: { id:getLoginUser()?.id},
    //   });
    //   this.props.action.customer.setNewCount(this.props.newCount + 1);
    //   this.props.action.customer.setIsNewMsg(true);
    //   const newMsg = {
    //     title: "客户新增",
    //     sendTime: this.$dayjs().valueOf(),
    //     content: msg?.name,
    //     customerId: msg?.customerId,
    //   };
    //   msgs.push(newMsg);
    //   this.props.action.customer.setMsgs(sortKey(msgs, "sendTime"));
    // }
    if(msg.type==="broadcast"){
      window.parent.postMessage({
        data :msg,type:"danmu"
      },'*');
    }
    if(msg.type==="callEnd"){
      this.props.action.customer.setPhoneLoading(false);
    }
    if(msg.type>1){
      this.$notify.notify({
        audio: {
          // 可以使用数组传多种格式的声音文件
          file: "../../assets/new.mp3",
          // 下面也是可以的哦
          // file: 'msg.mp4'
        },
        title:msg.type===3?"合同动态":"客户动态",
        body: msg.content,
        icon: msgIco,
        openurl: "http://8.136.213.14:10001/"+(msg.type===3?"contract_manager":"customer1-1"),
        onclick: function () {
          console.log("on click");
        },
        onshow: function () {
          console.log("on show");
        },
      });
      // this.$notify.setFavicon(10)
      this.$notify.setTitle(msg.content);
    }
    if(msg.type===1){
      this.$notification.info({message: '新客提醒',
      description:
        msg.content,
        icon: <SlIcon type={"icon_msg_new"} style={{ width: 30, height: 30 }} />,
        style:{backgroundColor:'rgb(192 197 194)'}
    });
      this.$notify.notify({
        audio: {
          // 可以使用数组传多种格式的声音文件
          file: "msg.m4a",
          // 下面也是可以的哦
          // file: 'msg.mp4'
        },
        title:"新客通知",
        body: msg.content,
        icon: msgIco,
        openurl: "http://8.136.213.14:10001/customer1-1",
        onclick: function () {
        },
        onshow: function () {
          loadAudioFile(wa)

        },
      });
      // this.$notify.setFavicon(10)
      this.$notify.setTitle(msg.content);
      this.$notify.setURL('wa.m4a')
     
      
    }
    //this.$notify.player();
    let $favicon = document.querySelector("link");
    $favicon.type = "image/x-icon";
    $favicon.rel = "shortcut icon";
    $favicon.href = msgIco;
  };
  handleSoecktMessage(msg, topic, id) {
    const { msgs } = this.props;
    switch (topic) {
      case "/customer/" + id + "/customer":
        this.$notification.info({
          message: "新客提醒",
          description: msg.content,
          icon: (
            <SlIcon type={"icon_msg_new"} style={{ width: 30, height: 30 }} />
          ),
          style: { backgroundColor: "rgb(192 197 194)" },
        });
        this.$notify.notify({
          audio: {
            // 可以使用数组传多种格式的声音文件
            file: "msg.m4a",
            // 下面也是可以的哦
            // file: 'msg.mp4'
          },
          title: "新客通知",
          body: msg.content,
          icon: msgIco,
          openurl: "http://8.136.213.14:10001/customer1-1",
          onclick: function () {},
          onshow: function () {
            loadAudioFile(wa);
          },
        });
        // this.$notify.setFavicon(10)
        this.$notify.setTitle(msg.content);
        this.$notify.setURL("wa.m4a");
        break;
      case "/customer/" + id + "/daily":
        break;
      case "/customer/" + id + "/monthly":
        break;
      case "/customer/" + id + "/customerDynamic":
        //读取客户量
        this.props.action.customer.upCustomerTop({
          params: { id: getLoginUser()?.id },
        });
        this.props.action.customer.upUnflollowCount({
          params: { id: getLoginUser()?.id },
        });
        this.props.action.customer.setNewCount(this.props.newCount + 1);
        this.props.action.customer.setIsNewMsg(true);
        const newMsg = {
          title: "客户动态",
          sendTime: this.$dayjs().valueOf(),
          content: msg?.name,
          customerId: msg?.customerId,
        };
        msgs.push(newMsg);
        this.props.action.customer.setMsgs(sortKey(msgs, "sendTime"));
        break;
      case "/customer/" + id + "/broadcast":
        window.parent.postMessage(
          {
            data: msg,
            type: "danmu",
          },
          "*"
        );
        break;
      case "/customer/" + id + "/callEnd":
        this.props.action.customer.setPhoneLoading(false);
        break;
      // case "/customer/" + id + "/customer_remind":
      //   this.props.action.system.setDoMsg(msg.content);
      //   this.props.action.system.showCard();
      //   break;
      case "/repayment_remind":
        this.props.action.customer.getReminds({params:{day:this.$dayjs().date(),userId:getLoginUser()?.id}});
        break;
      default:
        break;
    }
  }
  doClick(id) {
    Modal.confirm({
      okText: "确定",
      cancelText: "取消",
      title: "温馨提示",
      content: "你确定处理？处理后系统将不再提醒！",
      onOk: () => {
        // this.setState({ loading: true });
        this.props.ajax
          .del(`/customer_remind/${id}`)
          .then((res) => {
            this.$message.success("处理完成");
          })
          .finally();
      },
    });
  }
  callCPhone(phone, customerId) {
    this.props.action.customer.setPhoneLoading(true);
    if (phone.length > 14) {
      this.props.history.push("/customer1-4", { id: customerId });
    } else
      this.props.ajax
        .get("/sms/sms/call_phone", {
          id: getLoginUser()?.id,
          phone: phone,
          userPhone: "",
          customerId: customerId,
          tellType: "2",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          this.props.action.customer.setPhoneLoading(false);
        })
        .finally(() => {
          setTimeout(() => {
            this.props.action.customer.setPhoneLoading(false);
          }, 10000);
        });
  }
  goCustomer(id) {
    this.props.history.push("/customer1-4", { id: id });
  }
  goNext(index) {
    if (index < this.props.reminds.length - 1) {
    }
    this.props.action.customer.goNextRemind(index + 1);
  }
  getXH(){
    return  XHS[randomInt(0,11)];
  }

  render() {
    let {
      layout,
      pageHeadFixed,
      showPageHead,
      tabsShow,
      hideTabe,
      title,
      breadcrumbs,

      showSide,
      sideCollapsed,
      sideCollapsedWidth,
      sideWidth,
      globalLoading,
      globalLoadingTip,
      sideDragging,
      isReturn,
      showCard,
      lastReport,
      showReminds,
      reminds,
      crtRemind,
      phoneLoading,
    } = this.props;

    sideWidth = sideCollapsed ? sideCollapsedWidth : sideWidth;
    sideWidth = showSide ? sideWidth : 0;

    let transitionDuration = sideDragging ? "0ms" : `300ms`;

    const isTopSideMenu = layout === PAGE_FRAME_LAYOUT.TOP_SIDE_MENU;
    const isSideMenu = layout === PAGE_FRAME_LAYOUT.SIDE_MENU;
    const hasSide = isTopSideMenu || isSideMenu;

    if (!hasSide) {
      window.document.body.style.paddingLeft = "0px";
    } else {
      window.document.body.style.paddingLeft = `${sideWidth}px`;
    }

    const theme = "default"; // (isTopSideMenu || isSideMenu) ? 'dark' : 'default';

    let pageHead = null;
    if (showPageHead) {
      pageHead = <PageHead title={title} breadcrumbs={breadcrumbs} />;

      if (pageHeadFixed) {
        pageHead = (
          <div
            styleName={`page-head-fixed ${tabsShow ? "with-tabs" : ""}`}
            style={{ left: hasSide ? sideWidth : 0, transitionDuration }}
          >
            {pageHead}
          </div>
        );
      }
    }

    if (isSideMenu) pageHead = null;

    const titleText = title?.text || title;
    const titleIsString = typeof titleText === "string";

    const topSpaceClass = ["content-top-space"];

    if (showPageHead && pageHead && pageHeadFixed)
      topSpaceClass.push("with-fixed-page-head");
    if (tabsShow) topSpaceClass.push("with-tabs");

    const windowWidth = window.innerWidth;
    const sideWidthSpace = hasSide ? sideWidth : 0;
    return (
      <div styleName="base-frame" className="no-print">
         <Modal
                width={'600px'}
                style={{right:20,top:20}}
                bodyStyle={{padding:'0',textAlign:'center'}}
          title={
            <div
              styleName="report-card-header"
              // end
            >
              <span styleName="card-title">{"昨日日报 ~ "+(this.$dayjs().format("YYYY-MM-DD")+"           "+getNl(this.$dayjs().format("YYYY-MM-DD")))}</span> 
          </div>
          }
          closeIcon={<img src={require("../../assets/icon/close_card.png")} alt="" style={{width:36}}></img>}
          footer={false}
          visible={false}
          centered={true}
          onCancel={()=> this.props.action.system.hideCard()}
        //   modalRender={modal => <Draggable disabled={this.state.disabled}>{modal}</Draggable>}
        >
          <div styleName="report-card-body" style={{backgroundImage:"url(" +require("../../assets/imgs/xh_"+this.getXH()+".png") + ")"}}>
            <div styleName="report-card-warp">
            <div styleName="report-item">
              <div styleName="title"> 跟进:</div>  <span styleName="count">{lastReport?.dailyFollowUpCount || 0}</span>
              </div>
              <div styleName="report-item">
              <div styleName="title">上门:</div><span styleName="count">{lastReport?.visitCount || 0}</span>
              </div>
              <div styleName="report-item">
              <div styleName="title">签约:</div><span styleName="count">{lastReport?.signCount || 0}</span>
              </div>
              <div styleName="report-item">
              <div styleName="title">进件:</div><span styleName="count">{lastReport?.submitCount || 0}</span>
              </div>
              <div styleName="report-item">
              <div styleName="title">放款:</div><span styleName="count">{formatWang(lastReport?.loanMoney || 0)}</span>
              </div>
              <div styleName="report-item">
              <div styleName="title">收佣:</div><span styleName="count">{formatWang(lastReport?.collectMoneyCount || 0)}</span>
              </div>
              <div styleName="total">
                <span styleName="left"> 总评分：60</span><img styleName="right"   src={require("../../assets/imgs/good.png")} alt="" style={{width:60,height:60}}></img>
              </div>
            </div>
          </div>
         
        </Modal>
        <Modal
          width={"600px"}
          style={{ right: 20, top: 20, position: "fixed", borderRadius: 12 }}
          bodyStyle={{ padding: "0", textAlign: "center" }}
          title={
            <div
              styleName="report-card-header"
              // end
            >
              <span
                styleName="card-title"
                style={{ background: "red" }}
                onClick={() => {
                  this.goCustomer(reminds[crtRemind]?.customerId);
                }}
              >
                {"提醒客户还款     客户编码：" + reminds[crtRemind]?.code}
              </span>
              <SlButton
                onClick={() => {
                  this.goNext(crtRemind);
                }}
                disabled={crtRemind >= reminds.length - 1}
                type="success"
                title={"下一个  " + (crtRemind + 1) + "/" + reminds.length}
                style={{ position: "absolute", right: 60, top: 12 }}
              ></SlButton>
            </div>
          }
          closeIcon={
            <img
              src={require("../../assets/icon/close_card.png")}
              alt=""
              style={{ width: 35 }}
            ></img>
          }
          footer={false}
          visible={showReminds}
          maskClosable={false}
          centered={true}
          onCancel={() => this.props.action.customer.hideReminds()}
          //   modalRender={modal => <Draggable disabled={this.state.disabled}>{modal}</Draggable>}
        >
          <div styleName="do-card-body">
            <div styleName="line-item">
              {" "}
              <Badge color={"#2db7f5"} />
              <div styleName="title">主借贷人：</div>
              <div styleName="body">{reminds[crtRemind]?.name}</div>
            </div>
            <div styleName="line-item">
              {" "}
              <Badge color={"#2db7f5"} />
              <div styleName="title">联系电话：</div>
              <div styleName="body">{reminds[crtRemind]?.phone}</div>
            </div>
            <div styleName="line-item">
              {" "}
              <Badge color={"#2db7f5"} />
              <div styleName="title">放款机构：</div>
              <div styleName="body">{reminds[crtRemind]?.loanOrganization}</div>
            </div>
            <div styleName="line-item">
              {" "}
              <Badge color={"#2db7f5"} />
              <div styleName="title">还款方式：</div>
              <div styleName="body">{reminds[crtRemind]?.repaymentWay}</div>
            </div>
            <div styleName="line-item">
              {" "}
              <Badge color={"#2db7f5"} />
              <div styleName="title">还款日期：</div>
              <div styleName="body">{reminds[crtRemind]?.remindDate}</div>
            </div>
            <div styleName="do-time">
              <Icon
                type="clock-circle"
                theme="twoTone"
                twoToneColor="#2db7f5"
                style={{ marginRight: 4 }}
              />
              {this.$dayjs(reminds[crtRemind]?.created).format(
                "YYYY/MM/DD HH:mm"
              )}
            </div>
            <div style={{ position: "absolute", left: 60, bottom: 20 }}>
              <Spin
                spinning={phoneLoading}
                tip={"拨号中【" + reminds[crtRemind]?.phone + "】"}
              >
                <SlButton
                  onClick={() => {
                    this.callCPhone(
                      reminds[crtRemind].phone,
                      reminds[crtRemind].customerId
                    );
                  }}
                  type="success"
                  title="拨打电话 ☎️"
                ></SlButton>
              </Spin>
            </div>
          </div>
        </Modal>

          <SockJsClient
          url="https://bainianjiangren.com/wss/endpoint"
          headers={{userId:getLoginUser()?.id}}
          subscribeHeaders={{userId:getLoginUser()?.id}}
          topics={[
            "/customer/" + getLoginUser()?.id+"/customer",
            "/customer/" + getLoginUser()?.id+"/daily",
            "/customer/" + getLoginUser()?.id+"/monthly",
            "/customer/" + getLoginUser()?.id+"/customerDynamic",
            "/customer/" + getLoginUser()?.id+"/broadcast",
            "/customer/" + getLoginUser()?.id+"/callEnd",
            "/repayment_remind",
          ]}
          onMessage={(msg,topic) => {
            this.handleSoecktMessage(msg,topic,getLoginUser()?.id);
          }}
          ref={(client) => {
            this.clientRef = client;
          }}
          // sendMessage={this.client.send("/customer/" + getLoginUser()?.id+"/customer", {}, "hh")} //发送消息
        />
        <Helmet title={titleIsString ? titleText : ""} />
        <BackTop />
        <Header />
        <Side layout={layout} theme={theme} />
        <div styleName={topSpaceClass.join(" ")} style={{height:hideTabe?64:118}} />
        {pageHead}
        {tabsShow && hideTabe!==true? (
          <div
            styleName="page-tabs"
            style={{
              left: sideWidthSpace,
              // width: isReturn?windowWidth-15: windowWidth,
              transitionDuration,
              justifyContent: isReturn ? "space-between" : "",
              backgroundColor:"rgb(238,241,244,1)"
            }}
          >
            <Breadcrumb theme={theme} dataSource={breadcrumbs} />
            {isReturn ? (
              <SlButton
                type="success"
                size="small"
                onClick={() => {
                  this.props.action.system.closeTab(
                    this.props.history.location.pathname
                  );
                }}
                title="返回"
              ></SlButton>
            ) : null}
          </div>
        ) : null}
        <div
          styleName="global-loading"
          style={{ display: globalLoading ? "block" : "none" }}
        >
          <Spin spinning size={"large"} tip={globalLoadingTip}/>
        </div>
      </div>
    );
  }
}
