import React from 'react';
import './style.less';
import dayjs from "dayjs";
import {Avatar} from 'antd';
export default function (props) {
    const {data,onClick}=props
    return (
      <div className='log-item-warp' onClick={onClick}>
          <div className='log-left'>
            <div className='log-avtor'>
                <Avatar src={require(data.hasOwnProperty('type')?  (data.type===1? '../../../assets/icon/msg_more.png':(data.type===3?'../../../assets/icon/msg_ht.png':'../../../assets/icon/msg_new.png')):'../../../assets/icon/men.png')} className={data.hasOwnProperty('type')?'msg-avtor':'log-avtor'}/>
            </div>
            <div className='log-content-warp'>
                <div className='log-title'>
                    {data.hasOwnProperty('type')?(data.type===1?"客户状态修改":(data.type===3?"合同动态":"新客通知")) : data.name}
                </div>
                <div className='log-content' dangerouslySetInnerHTML={{__html:data.hasOwnProperty('type')?data.content:data.followRecord}}>
                    {/* {data.hasOwnProperty('type')?data.content:data.followRecord} */}
                </div>
            </div>
          </div>
          <div className='log-right'>
              {dayjs(data.created).format('YYYY-MM-DD HH:mm')}
          </div>
      </div>
    );
}
